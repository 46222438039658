import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatDialogModule } from '@angular/material/dialog';
import { MatMenuModule } from '@angular/material/menu';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { NgxOtpInputModule } from 'ngx-otp-input';
import { MatRadioModule } from '@angular/material/radio';
import { NgxVideoListPlayerModule } from 'ngx-video-list-player';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { MatStepperModule } from '@angular/material/stepper';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LandingPageComponent } from './interface/common/landing-page/landing-page.component';
import { MemberModule } from './interface/member/member.module';
import { StudioModule } from './interface/studio/studio.module';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { CoreModule } from './common/interceptor/core.module';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ImageCropperModule } from 'ngx-image-cropper';

@NgModule({
  declarations: [
    AppComponent,
    LandingPageComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatDialogModule,
    MatMenuModule,
    MatCheckboxModule,
    MatSnackBarModule,
    NgxOtpInputModule,
    MatRadioModule,
    NgxSpinnerModule,
    NgxVideoListPlayerModule,
    MemberModule,
    MatStepperModule,
    FormsModule,
    CoreModule,
    ReactiveFormsModule,
    StudioModule,
    ImageCropperModule

  ],
  providers: [{ provide: LocationStrategy, useClass: HashLocationStrategy }],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent]
})
export class AppModule { }
