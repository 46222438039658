<app-header></app-header>

<section class="breadcrumb-banner">
  <div class="container">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item ps-0 active" [routerLink]="['/home']">Home</li>
        <li class="breadcrumb-item active" [routerLink]="['/models']">Models</li>
        <li class="breadcrumb-item">{{modelData?.name}}</li>
      </ol>
    </nav>
  </div>
</section>


<!-- <section class="pb-4">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6">
               <div class="artist_img position-relative">
                   <img src="assets/images/models/model-1.jpg"/>
    
                   <div class="artist_personal_info position-relative d-flex">
                   <div class="info me-4">
                    <p class="mb-2 fw-bold">Age</p>
                    <h4 class="fw-light m-0">29</h4>
                  </div>
    
                  <div class="info">
                    <p class="mb-2 fw-bold">Gender</p>
                    <h4 class="fw-light m-0">Female</h4>
                  </div>
                </div>
               </div>
               
            </div>
            <div class="col-lg-6">
                <h3 class="fw-light mb-4">Erika Jordan</h3>
                <p class="fw-light color_grey bio">
                    Erika Jordan is an amazing, strong woman. She is an amazing wrestler and referee for our wrestling site Ultimate Surrender. If you see her naked you will notice that her body is all rippling muscle - but not without softness and curves too. In bondage these muscles really stand out as she is straining to orgasm, if she is allowed. For the most part, she can be seen completely dominating on the wrestling mats. She even dominates when it comes to champion wrestlers like Izamar Guttierez. There have been many times when she has been so victorious that the champion fuck has had to be taken onto another channel like Everything Butt or Whipped Ass so she can get her full revenge.
                </p>
    
                <div class="artist_tags d-flex">
                    <p class="mb-0">Tags:</p>   
                    <div class="d-flex flex-wrap"><span class="color_pink">Brunette</span> <span class="color_pink">Muscle</span> <span class="color_pink"> Muscular</span> <span class="color_pink">Natural Boobs</span>   
                    </div>
                </div>
            </div>
        </div>
    </div>
</section> -->

<section class="Model_details_section">
  <div class="container">
    <div class="row align-items-start">
      <div class="col-lg-4">
        <div class="model_img">
          <img [src]="modelUrl + modelData?.profile" class="fit-img" />
        </div>
      </div>
      <div class="col-lg-8">
        <h3 class="fw-light mb-1 ">{{modelData?.name}}</h3>
        <div class="d-flex align-items-center gap-4 subtext">
          <p class="mb-2">Available for customs: <span *ngIf="modelData.isCustom=='0'">No</span><span *ngIf="modelData.isCustom=='1'">Yes</span></p>
        </div>
        <!-- <p class="studio">Studio: <span (click)="studioDetail(modelData.id)">{{modelData?.username}}</span></p> -->

        <p class="studio">Studio: <span>{{modelData?.username}}</span></p>
        <p class="fw-light color_grey bio">Bio:
         {{modelData?.bio}}

        </p>

        <div class="artist_tags d-flex" *ngIf="tags?.length!=0">
          <p class="mb-0">Tags:</p>
          <div class="d-flex flex-wrap" *ngFor="let tag of tags"><span class="color_pink">{{tag.name}}</span> 
          </div>
        </div>
      </div>
      <hr>
    </div>
  </div>
</section>



<section class="pt-5">
  <div class="container" *ngIf="clips?.length!=0">
    <div class="relatedVideo_contaienr">
      <h4 class="fw-medium mb-2">Artist Videos</h4>

      <div class="commonVideo_container position-relative">
        <div class="commonVideo_slider">
          <!-- Slide One -->
          <div class="videoBox position-relative" *ngFor="let clip of clips">
            <!-- <div class="videoCoverImg">
              <img class="img-fluid" src="assets/images/Vimg6.jpg" alt="">
            </div> -->
            <div class="video_container position-relative">
              <video onmouseover="this.play()" onmouseout="this.pause();">
                <source [src]="clipUrl + clip.trailer" type="video/mp4">
              </video>
            </div>
            
           
              <div class="likeContainer d-flex align-items-center justify-content-center ms-auto me-3 cursor_pointer position-absolute"
                (click)="fav(clip.id,clip.isFav)"   [ngClass]="{'heart_filled': clip.isFav == '1'}">
                <svg xmlns="http://www.w3.org/2000/svg" width="26.927" height="24" viewBox="0 0 26.927 24">
                  <g id="Icon_feather-heart" data-name="Icon feather-heart" transform="translate(-0.327 -2.497)"
                    fill="none" stroke-linecap="round" stroke-linejoin="round">
                    <path
                      d="M23.485,6.265a6.033,6.033,0,0,0-8.535,0L13.788,7.428,12.625,6.265A6.035,6.035,0,1,0,4.091,14.8l1.163,1.163L13.788,24.5l8.535-8.535L23.485,14.8a6.033,6.033,0,0,0,0-8.535Z"
                      stroke="none" />
                    <path
                      d="M 19.218017578125 4.497261047363281 C 17.67348861694336 4.497261047363281 16.12896156311035 5.086641311645508 14.95075130462646 6.265401840209961 L 13.78795146942139 7.428211212158203 L 12.62515163421631 6.265401840209961 C 10.26840209960938 3.908658981323242 6.447360992431641 3.908660888671875 4.090621948242188 6.265401840209961 C 1.733880996704102 8.622150421142578 1.733880996704102 12.443190574646 4.090621948242188 14.79993057250977 L 13.78795146942139 24.49726104736328 L 23.48528099060059 14.79993057250977 C 25.84280204772949 12.44351100921631 25.84280204772949 8.621820449829102 23.48528099060059 6.265401840209961 C 22.30707168579102 5.086641311645508 20.76254463195801 4.497261047363281 19.218017578125 4.497261047363281 M 19.2180118560791 2.497261047363281 C 21.36453247070312 2.497261047363281 23.38237190246582 3.333351135253906 24.89982223510742 4.851520538330078 C 26.41733169555664 6.368310928344727 27.25342178344727 8.386150360107422 27.25342178344727 10.53266143798828 C 27.25342178344727 12.67918109893799 26.41733169555664 14.69702053070068 24.89916229248047 16.21446990966797 L 15.20216178894043 25.91147041320801 C 14.82709121704102 26.28655052185059 14.31838130950928 26.49726104736328 13.78795146942139 26.49726104736328 C 13.2575216293335 26.49726104736328 12.74881172180176 26.28655052185059 12.37374114990234 25.91147041320801 L 2.676410675048828 16.21414184570312 C -0.4563579559326172 13.08137130737305 -0.4563694000244141 7.983970642089844 2.676401138305664 4.851190567016602 C 4.193981170654297 3.333610534667969 6.211702346801758 2.497840881347656 8.357881546020508 2.497840881347656 C 10.38759994506836 2.497840881347656 12.30235290527344 3.245304107666016 13.78789329528809 4.610126495361328 C 15.27305603027344 3.245044708251953 17.18797302246094 2.497261047363281 19.2180118560791 2.497261047363281 Z"
                      stroke="none" fill="#fff" />
                  </g>
                </svg>
              </div>
<!-- 
              <div class="duration_box position-absolute">
                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                  <path
                    d="M6.99984 12.8332C10.2215 12.8332 12.8332 10.2215 12.8332 6.99984C12.8332 3.77818 10.2215 1.1665 6.99984 1.1665C3.77818 1.1665 1.1665 3.77818 1.1665 6.99984C1.1665 10.2215 3.77818 12.8332 6.99984 12.8332Z"
                    stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M9 9L7.39067 7.793C7.1406 7.60551 7.00008 7.35119 7 7.086V4" stroke="white"
                    stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
                <span class="small ms-1">1:15:30</span>
              </div> -->
                 <div class="desBox d-flex position-absolute p-3 w-100">
                <div class="description">
                  <p class="small color_pink mb-1">{{clip.title}}</p>
                  <h6 class="fs18  mb-0 desHeading">{{clip.description}}</h6>
                </div>
                <div class="price_count text-end">
                  <p class="fs13 mb-1 opacity-50 text-uppercase">{{clip.created_at | date}}</p>
                  <h6 class="mb-0 d-flex align-items-center justify-content-end"><i class="me-2"><img
                        src="assets/images/coin.svg" alt=""></i>
                   {{clip.price}}</h6>
                </div>
              </div>
          
          </div>
          <!-- Slide Two -->

          <!-- <div class="videoBox position-relative">
            <div class="videoCoverImg">
              <img class="img-fluid" src="assets/images/Vimg7.jpg" alt="">
            </div>
            <div class="absolute_box position-absolute h-100 w-100">
              <div class="likeContainer d-flex align-items-center justify-content-center ms-auto me-3 cursor_pointer"
                (click)="fav()">
                <svg xmlns="http://www.w3.org/2000/svg" width="26.927" height="24" viewBox="0 0 26.927 24">
                  <g id="Icon_feather-heart" data-name="Icon feather-heart" transform="translate(-0.327 -2.497)"
                    fill="none" stroke-linecap="round" stroke-linejoin="round">
                    <path
                      d="M23.485,6.265a6.033,6.033,0,0,0-8.535,0L13.788,7.428,12.625,6.265A6.035,6.035,0,1,0,4.091,14.8l1.163,1.163L13.788,24.5l8.535-8.535L23.485,14.8a6.033,6.033,0,0,0,0-8.535Z"
                      stroke="none" />
                    <path
                      d="M 19.218017578125 4.497261047363281 C 17.67348861694336 4.497261047363281 16.12896156311035 5.086641311645508 14.95075130462646 6.265401840209961 L 13.78795146942139 7.428211212158203 L 12.62515163421631 6.265401840209961 C 10.26840209960938 3.908658981323242 6.447360992431641 3.908660888671875 4.090621948242188 6.265401840209961 C 1.733880996704102 8.622150421142578 1.733880996704102 12.443190574646 4.090621948242188 14.79993057250977 L 13.78795146942139 24.49726104736328 L 23.48528099060059 14.79993057250977 C 25.84280204772949 12.44351100921631 25.84280204772949 8.621820449829102 23.48528099060059 6.265401840209961 C 22.30707168579102 5.086641311645508 20.76254463195801 4.497261047363281 19.218017578125 4.497261047363281 M 19.2180118560791 2.497261047363281 C 21.36453247070312 2.497261047363281 23.38237190246582 3.333351135253906 24.89982223510742 4.851520538330078 C 26.41733169555664 6.368310928344727 27.25342178344727 8.386150360107422 27.25342178344727 10.53266143798828 C 27.25342178344727 12.67918109893799 26.41733169555664 14.69702053070068 24.89916229248047 16.21446990966797 L 15.20216178894043 25.91147041320801 C 14.82709121704102 26.28655052185059 14.31838130950928 26.49726104736328 13.78795146942139 26.49726104736328 C 13.2575216293335 26.49726104736328 12.74881172180176 26.28655052185059 12.37374114990234 25.91147041320801 L 2.676410675048828 16.21414184570312 C -0.4563579559326172 13.08137130737305 -0.4563694000244141 7.983970642089844 2.676401138305664 4.851190567016602 C 4.193981170654297 3.333610534667969 6.211702346801758 2.497840881347656 8.357881546020508 2.497840881347656 C 10.38759994506836 2.497840881347656 12.30235290527344 3.245304107666016 13.78789329528809 4.610126495361328 C 15.27305603027344 3.245044708251953 17.18797302246094 2.497261047363281 19.2180118560791 2.497261047363281 Z"
                      stroke="none" fill="#fff" />
                  </g>
                </svg>
              </div>

              <div class="duration_box position-absolute">
                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                  <path
                    d="M6.99984 12.8332C10.2215 12.8332 12.8332 10.2215 12.8332 6.99984C12.8332 3.77818 10.2215 1.1665 6.99984 1.1665C3.77818 1.1665 1.1665 3.77818 1.1665 6.99984C1.1665 10.2215 3.77818 12.8332 6.99984 12.8332Z"
                    stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M9 9L7.39067 7.793C7.1406 7.60551 7.00008 7.35119 7 7.086V4" stroke="white"
                    stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
                <span class="small ms-1">1:15:30</span>
              </div>
                 <div class="desBox d-flex position-absolute p-3 w-100">
                <div class="description">
                  <p class="small color_pink mb-1">Category Two</p>
                  <h6 class="fs18 text-truncate mb-0 desHeading">Penelope Kay: Fucked in Tight Bondage</h6>
                </div>
                <div class="price_count text-end">
                  <p class="fs13 mb-1 opacity-50 text-uppercase">JUN 15, 2020</p>
                  <h6 class="mb-0 d-flex align-items-center justify-content-end"><i class="me-2"><img
                        src="assets/images/coin.svg" alt=""></i>
                    10</h6>
                </div>
              </div>
            </div>
          </div> -->
          <!-- Slide Three -->
<!-- 
          <div class="videoBox position-relative">
            <div class="videoCoverImg">
              <img class="img-fluid" src="assets/images/Vimg8.jpg" alt="">
            </div>
            <div class="absolute_box position-absolute h-100 w-100">
              <div class="likeContainer d-flex align-items-center justify-content-center ms-auto me-3 cursor_pointer"
                (click)="fav()">
                <svg xmlns="http://www.w3.org/2000/svg" width="26.927" height="24" viewBox="0 0 26.927 24">
                  <g id="Icon_feather-heart" data-name="Icon feather-heart" transform="translate(-0.327 -2.497)"
                    fill="none" stroke-linecap="round" stroke-linejoin="round">
                    <path
                      d="M23.485,6.265a6.033,6.033,0,0,0-8.535,0L13.788,7.428,12.625,6.265A6.035,6.035,0,1,0,4.091,14.8l1.163,1.163L13.788,24.5l8.535-8.535L23.485,14.8a6.033,6.033,0,0,0,0-8.535Z"
                      stroke="none" />
                    <path
                      d="M 19.218017578125 4.497261047363281 C 17.67348861694336 4.497261047363281 16.12896156311035 5.086641311645508 14.95075130462646 6.265401840209961 L 13.78795146942139 7.428211212158203 L 12.62515163421631 6.265401840209961 C 10.26840209960938 3.908658981323242 6.447360992431641 3.908660888671875 4.090621948242188 6.265401840209961 C 1.733880996704102 8.622150421142578 1.733880996704102 12.443190574646 4.090621948242188 14.79993057250977 L 13.78795146942139 24.49726104736328 L 23.48528099060059 14.79993057250977 C 25.84280204772949 12.44351100921631 25.84280204772949 8.621820449829102 23.48528099060059 6.265401840209961 C 22.30707168579102 5.086641311645508 20.76254463195801 4.497261047363281 19.218017578125 4.497261047363281 M 19.2180118560791 2.497261047363281 C 21.36453247070312 2.497261047363281 23.38237190246582 3.333351135253906 24.89982223510742 4.851520538330078 C 26.41733169555664 6.368310928344727 27.25342178344727 8.386150360107422 27.25342178344727 10.53266143798828 C 27.25342178344727 12.67918109893799 26.41733169555664 14.69702053070068 24.89916229248047 16.21446990966797 L 15.20216178894043 25.91147041320801 C 14.82709121704102 26.28655052185059 14.31838130950928 26.49726104736328 13.78795146942139 26.49726104736328 C 13.2575216293335 26.49726104736328 12.74881172180176 26.28655052185059 12.37374114990234 25.91147041320801 L 2.676410675048828 16.21414184570312 C -0.4563579559326172 13.08137130737305 -0.4563694000244141 7.983970642089844 2.676401138305664 4.851190567016602 C 4.193981170654297 3.333610534667969 6.211702346801758 2.497840881347656 8.357881546020508 2.497840881347656 C 10.38759994506836 2.497840881347656 12.30235290527344 3.245304107666016 13.78789329528809 4.610126495361328 C 15.27305603027344 3.245044708251953 17.18797302246094 2.497261047363281 19.2180118560791 2.497261047363281 Z"
                      stroke="none" fill="#fff" />
                  </g>
                </svg>
              </div>

              <div class="duration_box position-absolute">
                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                  <path
                    d="M6.99984 12.8332C10.2215 12.8332 12.8332 10.2215 12.8332 6.99984C12.8332 3.77818 10.2215 1.1665 6.99984 1.1665C3.77818 1.1665 1.1665 3.77818 1.1665 6.99984C1.1665 10.2215 3.77818 12.8332 6.99984 12.8332Z"
                    stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M9 9L7.39067 7.793C7.1406 7.60551 7.00008 7.35119 7 7.086V4" stroke="white"
                    stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
                <span class="small ms-1">1:15:30</span>
              </div>
                 <div class="desBox d-flex position-absolute p-3 w-100">
                <div class="description">
                  <p class="small color_pink mb-1">Category Three</p>
                  <h6 class="fs18 text-truncate mb-0 desHeading">Jessie Saint - Fresh Hot Meat 2022</h6>
                </div>
                <div class="price_count text-end">
                  <p class="fs13 mb-1 opacity-50 text-uppercase">JUN 15, 2020</p>
                  <h6 class="mb-0 d-flex align-items-center justify-content-end"><i class="me-2"><img
                        src="assets/images/coin.svg" alt=""></i>
                    10</h6>
                </div>
              </div>
            </div>
          </div> -->
          <!-- Slide Four -->
          <!-- <div class="videoBox position-relative">
            <div class="videoCoverImg">
              <img class="img-fluid" src="assets/images/Vimg9.jpg" alt="">
            </div>
            <div class="absolute_box position-absolute h-100 w-100">
              <div class="likeContainer d-flex align-items-center justify-content-center ms-auto me-3 cursor_pointer"
                (click)="fav()">
                <svg xmlns="http://www.w3.org/2000/svg" width="26.927" height="24" viewBox="0 0 26.927 24">
                  <g id="Icon_feather-heart" data-name="Icon feather-heart" transform="translate(-0.327 -2.497)"
                    fill="none" stroke-linecap="round" stroke-linejoin="round">
                    <path
                      d="M23.485,6.265a6.033,6.033,0,0,0-8.535,0L13.788,7.428,12.625,6.265A6.035,6.035,0,1,0,4.091,14.8l1.163,1.163L13.788,24.5l8.535-8.535L23.485,14.8a6.033,6.033,0,0,0,0-8.535Z"
                      stroke="none" />
                    <path
                      d="M 19.218017578125 4.497261047363281 C 17.67348861694336 4.497261047363281 16.12896156311035 5.086641311645508 14.95075130462646 6.265401840209961 L 13.78795146942139 7.428211212158203 L 12.62515163421631 6.265401840209961 C 10.26840209960938 3.908658981323242 6.447360992431641 3.908660888671875 4.090621948242188 6.265401840209961 C 1.733880996704102 8.622150421142578 1.733880996704102 12.443190574646 4.090621948242188 14.79993057250977 L 13.78795146942139 24.49726104736328 L 23.48528099060059 14.79993057250977 C 25.84280204772949 12.44351100921631 25.84280204772949 8.621820449829102 23.48528099060059 6.265401840209961 C 22.30707168579102 5.086641311645508 20.76254463195801 4.497261047363281 19.218017578125 4.497261047363281 M 19.2180118560791 2.497261047363281 C 21.36453247070312 2.497261047363281 23.38237190246582 3.333351135253906 24.89982223510742 4.851520538330078 C 26.41733169555664 6.368310928344727 27.25342178344727 8.386150360107422 27.25342178344727 10.53266143798828 C 27.25342178344727 12.67918109893799 26.41733169555664 14.69702053070068 24.89916229248047 16.21446990966797 L 15.20216178894043 25.91147041320801 C 14.82709121704102 26.28655052185059 14.31838130950928 26.49726104736328 13.78795146942139 26.49726104736328 C 13.2575216293335 26.49726104736328 12.74881172180176 26.28655052185059 12.37374114990234 25.91147041320801 L 2.676410675048828 16.21414184570312 C -0.4563579559326172 13.08137130737305 -0.4563694000244141 7.983970642089844 2.676401138305664 4.851190567016602 C 4.193981170654297 3.333610534667969 6.211702346801758 2.497840881347656 8.357881546020508 2.497840881347656 C 10.38759994506836 2.497840881347656 12.30235290527344 3.245304107666016 13.78789329528809 4.610126495361328 C 15.27305603027344 3.245044708251953 17.18797302246094 2.497261047363281 19.2180118560791 2.497261047363281 Z"
                      stroke="none" fill="#fff" />
                  </g>
                </svg>
              </div>

              <div class="duration_box position-absolute">
                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                  <path
                    d="M6.99984 12.8332C10.2215 12.8332 12.8332 10.2215 12.8332 6.99984C12.8332 3.77818 10.2215 1.1665 6.99984 1.1665C3.77818 1.1665 1.1665 3.77818 1.1665 6.99984C1.1665 10.2215 3.77818 12.8332 6.99984 12.8332Z"
                    stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M9 9L7.39067 7.793C7.1406 7.60551 7.00008 7.35119 7 7.086V4" stroke="white"
                    stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
                <span class="small ms-1">1:15:30</span>
              </div>
                 <div class="desBox d-flex position-absolute p-3 w-100">
                <div class="description">
                  <p class="small color_pink mb-1">Category Four</p>
                  <h6 class="fs18 text-truncate mb-0 desHeading">Asiatische Strassenhure abgeschleppt und vor der kamera
                    hart gefickt</h6>
                </div>
                <div class="price_count text-end">
                  <p class="fs13 mb-1 opacity-50 text-uppercase">JUN 15, 2020</p>
                  <h6 class="mb-0 d-flex align-items-center justify-content-end"><i class="me-2"><img
                        src="assets/images/coin.svg" alt=""></i>
                    10</h6>
                </div>
              </div>
            </div>
          </div> -->

          <!-- Slide Five -->
          <!-- <div class="videoBox position-relative">
            <div class="videoCoverImg">
              <img class="img-fluid" src="assets/images/Vimg5.jpg" alt="">
            </div>
            <div class="absolute_box position-absolute h-100 w-100">
              <div class="likeContainer d-flex align-items-center justify-content-center ms-auto me-3 cursor_pointer"
                (click)="fav()">
                <svg xmlns="http://www.w3.org/2000/svg" width="26.927" height="24" viewBox="0 0 26.927 24">
                  <g id="Icon_feather-heart" data-name="Icon feather-heart" transform="translate(-0.327 -2.497)"
                    fill="none" stroke-linecap="round" stroke-linejoin="round">
                    <path
                      d="M23.485,6.265a6.033,6.033,0,0,0-8.535,0L13.788,7.428,12.625,6.265A6.035,6.035,0,1,0,4.091,14.8l1.163,1.163L13.788,24.5l8.535-8.535L23.485,14.8a6.033,6.033,0,0,0,0-8.535Z"
                      stroke="none" />
                    <path
                      d="M 19.218017578125 4.497261047363281 C 17.67348861694336 4.497261047363281 16.12896156311035 5.086641311645508 14.95075130462646 6.265401840209961 L 13.78795146942139 7.428211212158203 L 12.62515163421631 6.265401840209961 C 10.26840209960938 3.908658981323242 6.447360992431641 3.908660888671875 4.090621948242188 6.265401840209961 C 1.733880996704102 8.622150421142578 1.733880996704102 12.443190574646 4.090621948242188 14.79993057250977 L 13.78795146942139 24.49726104736328 L 23.48528099060059 14.79993057250977 C 25.84280204772949 12.44351100921631 25.84280204772949 8.621820449829102 23.48528099060059 6.265401840209961 C 22.30707168579102 5.086641311645508 20.76254463195801 4.497261047363281 19.218017578125 4.497261047363281 M 19.2180118560791 2.497261047363281 C 21.36453247070312 2.497261047363281 23.38237190246582 3.333351135253906 24.89982223510742 4.851520538330078 C 26.41733169555664 6.368310928344727 27.25342178344727 8.386150360107422 27.25342178344727 10.53266143798828 C 27.25342178344727 12.67918109893799 26.41733169555664 14.69702053070068 24.89916229248047 16.21446990966797 L 15.20216178894043 25.91147041320801 C 14.82709121704102 26.28655052185059 14.31838130950928 26.49726104736328 13.78795146942139 26.49726104736328 C 13.2575216293335 26.49726104736328 12.74881172180176 26.28655052185059 12.37374114990234 25.91147041320801 L 2.676410675048828 16.21414184570312 C -0.4563579559326172 13.08137130737305 -0.4563694000244141 7.983970642089844 2.676401138305664 4.851190567016602 C 4.193981170654297 3.333610534667969 6.211702346801758 2.497840881347656 8.357881546020508 2.497840881347656 C 10.38759994506836 2.497840881347656 12.30235290527344 3.245304107666016 13.78789329528809 4.610126495361328 C 15.27305603027344 3.245044708251953 17.18797302246094 2.497261047363281 19.2180118560791 2.497261047363281 Z"
                      stroke="none" fill="#fff" />
                  </g>
                </svg>
              </div>

              <div class="duration_box position-absolute">
                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                  <path
                    d="M6.99984 12.8332C10.2215 12.8332 12.8332 10.2215 12.8332 6.99984C12.8332 3.77818 10.2215 1.1665 6.99984 1.1665C3.77818 1.1665 1.1665 3.77818 1.1665 6.99984C1.1665 10.2215 3.77818 12.8332 6.99984 12.8332Z"
                    stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M9 9L7.39067 7.793C7.1406 7.60551 7.00008 7.35119 7 7.086V4" stroke="white"
                    stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
                <span class="small ms-1">1:15:30</span>
              </div>
                 <div class="desBox d-flex position-absolute p-3 w-100">
                <div class="description">
                  <p class="small color_pink mb-1">Category Five</p>
                  <h6 class="fs18 text-truncate mb-0 desHeading">Brunette Asian wants some dick inside of her</h6>
                </div>
                <div class="price_count text-end">
                  <p class="fs13 mb-1 opacity-50 text-uppercase">JUN 15, 2020</p>
                  <h6 class="mb-0 d-flex align-items-center justify-content-end"><i class="me-2"><img
                        src="assets/images/coin.svg" alt=""></i>
                    10</h6>
                </div>
              </div>
            </div>
          </div> -->
        </div>

        <!-- arrows -->
        <div class="arrowSmall position-absolute w-100">
          <ul class="list-unstyled d-flex align-items-center justify-content-between m-0">
            <li class="prev"></li>
            <li class="next"></li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</section>
<app-footer></app-footer>