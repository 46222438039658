<app-header></app-header>

<section class="add-clip pb-5">
    <form [formGroup]="addClipForm">
        <div class="container">
            <button class="backbtn" [routerLink]="['/studio-clips']"><img
                    src="assets/images/studiomodule/backbtn.svg"></button>

            <div class="row">
                <div class="col-md-6">
                    <div
                        class="media-wrap video position-relative d-flex align-items-center justify-content-center flex-column mb-3">
                        <img *ngIf="!clip" src="assets/images/studiomodule/videoicn.svg">
                        <video *ngIf="clip" class="img-fluid" [src]="clip" height="100%" controls
                            controlsList="nodownload">
                            <!-- <source [src]="trailer" type='video/mp4' /> -->
                        </video>
                        <p *ngIf="!clip" class="upload-text fs20 mb-0 pt-3">Upload Video</p>
                        <input *ngIf="!clip" class="upload-input" type="file" formControlName="uploadVideo"
                            (change)="onFileChange($event,'clip')" accept="image/*,video/*">
                        <button (click)="removeImage('clip')" _ngcontent-ieh-c64=""
                            class="btn close_btn shadow-none"><svg _ngcontent-ieh-c64="" width="12" height="11"
                                viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path _ngcontent-ieh-c64="" fill-rule="evenodd" clip-rule="evenodd"
                                    d="M0.554908 0.494739C0.861401 0.216109 1.35833 0.216109 1.66482 0.494739L11.5549 9.48573C11.8614 9.76436 11.8614 10.2161 11.5549 10.4947C11.2484 10.7734 10.7515 10.7734 10.445 10.4947L0.554908 1.50375C0.248415 1.22512 0.248415 0.773369 0.554908 0.494739Z"
                                    fill="white"></path>
                                <path _ngcontent-ieh-c64="" fill-rule="evenodd" clip-rule="evenodd"
                                    d="M11.5549 0.503308C11.8614 0.781938 11.8614 1.23369 11.5549 1.51232L1.66482 10.5033C1.35833 10.7819 0.861402 10.7819 0.554909 10.5033C0.248415 10.2247 0.248416 9.77293 0.554909 9.4943L10.445 0.503308C10.7515 0.224678 11.2484 0.224678 11.5549 0.503308Z"
                                    fill="white"></path>
                            </svg></button>
                            <div *ngIf="uploadProgress !== undefined && uploadProgress < 100">
                                <p>Upload Progress: {{ uploadProgress }}%</p>
                              </div>
                    </div>
                    <div class="d-flex two_media">
                        <div
                            class="media-wrap flex_1 video position-relative d-flex align-items-center justify-content-center flex-column me-2">

                            <button (click)="removeImage('trailer')" _ngcontent-ieh-c64=""
                                class="btn close_btn shadow-none"><svg width="12" height="11" viewBox="0 0 12 11"
                                    fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path _ngcontent-iqo-c178="" _ngcontent-ieh-c64="" fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M0.554908 0.494739C0.861401 0.216109 1.35833 0.216109 1.66482 0.494739L11.5549 9.48573C11.8614 9.76436 11.8614 10.2161 11.5549 10.4947C11.2484 10.7734 10.7515 10.7734 10.445 10.4947L0.554908 1.50375C0.248415 1.22512 0.248415 0.773369 0.554908 0.494739Z"
                                        fill="white"></path>
                                    <path _ngcontent-iqo-c178="" fill-rule="evenodd" clip-rule="evenodd"
                                        d="M11.5549 0.503308C11.8614 0.781938 11.8614 1.23369 11.5549 1.51232L1.66482 10.5033C1.35833 10.7819 0.861402 10.7819 0.554909 10.5033C0.248415 10.2247 0.248416 9.77293 0.554909 9.4943L10.445 0.503308C10.7515 0.224678 11.2484 0.224678 11.5549 0.503308Z"
                                        fill="white"></path>
                                </svg></button>

                            <img *ngIf="!trailer" src="assets/images/studiomodule/videoicn.svg">
                            <!-- <video *ngIf="trailer" class="img-fluid" [src]="trailer"></video> -->
                            <video *ngIf="trailer" class="img-fluid" [src]="trailer" height="100%" controls
                                controlsList="nodownload">
                                <!-- <source [src]="trailer" type='video/mp4' /> -->
                            </video>
                            <p *ngIf="!trailer" class="upload-text fs20 mb-0 pt-3">Upload trailer</p>
                            <input *ngIf="!trailer" class="upload-input" type="file" formControlName="uploadTrailer"
                                (change)="onFileChange($event,'trailer')" accept="image/*,video/*">
                        </div>
                        <div
                            class="media-wrap flex_1 video position-relative ms-2 d-flex align-items-center justify-content-center flex-column">

                            <button (click)="removeImage('image')" _ngcontent-ieh-c64=""
                                class="btn close_btn shadow-none"><svg width="12" height="11" viewBox="0 0 12 11"
                                    fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path _ngcontent-iqo-c178="" _ngcontent-ieh-c64="" fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M0.554908 0.494739C0.861401 0.216109 1.35833 0.216109 1.66482 0.494739L11.5549 9.48573C11.8614 9.76436 11.8614 10.2161 11.5549 10.4947C11.2484 10.7734 10.7515 10.7734 10.445 10.4947L0.554908 1.50375C0.248415 1.22512 0.248415 0.773369 0.554908 0.494739Z"
                                        fill="white"></path>
                                    <path _ngcontent-iqo-c178="" fill-rule="evenodd" clip-rule="evenodd"
                                        d="M11.5549 0.503308C11.8614 0.781938 11.8614 1.23369 11.5549 1.51232L1.66482 10.5033C1.35833 10.7819 0.861402 10.7819 0.554909 10.5033C0.248415 10.2247 0.248416 9.77293 0.554909 9.4943L10.445 0.503308C10.7515 0.224678 11.2484 0.224678 11.5549 0.503308Z"
                                        fill="white"></path>
                                </svg></button>
                            <img *ngIf="!image" src="assets/images/studiomodule/imgicn.svg">
                            <img *ngIf="image" class="img-fluid fit-img " [src]="image">

                            <p *ngIf="!image" class="upload-text fs20 mb-0 pt-3">Upload Cover Image</p>
                            <input class="upload-input" type="file" formControlName="uploadCoverImage"
                                (change)="onFileChange($event,'image')" accept="image/*">
                        </div>
                    </div>
                </div>


                <div class="col-md-6">
                    <div class="form-sec">
                        <!-- <form> -->
                        <div class="mb-4 pb-1">
                            <input type="text" class="form-control fs18 cmn-input input_60 ff_Roboto"
                                placeholder="Video Title" formControlName="title" (keydown)="checkEvent($event)">
                        </div>

                        <div class="mb-4 pb-1">
                            <div class="priceinput position-relative">
                                <span class="fs18 fw-normal text-white dlr">$</span>
                                <input type="number" class="form-control fs18 cmn-input input_60 ff_Roboto"
                                    (keypress)="numberOnly($event)" placeholder="Price" formControlName="price"
                                    (keydown)="checkEvent($event)">
                            </div>
                        </div>

                        <div class="mb-4 pb-1">
                            <!-- <select multiple  class="selectpicker form-select fs18 cmn-input input_60 ff_Roboto"
                                [ngClass]="{'placeholder-color': categoryvalue === 'Category'}"
                                (ngModelChange)="categoryClass($event)" formControlName="categoryvalue">
                                <option value="" disabled selected hidden>Select Category</option>
                                <option placeholder='' value="{{categoryvalue}}" [selected]="categoryvalue.name"
                                    [ngValue]="categoryvalue" *ngFor="let categoryvalue of categories; let i=index;">
                                    {{categoryvalue.name}}</option>

                     
                            </select> -->

                            <!-- <mat-form-field class="no-line w-100  cmn-input c_multisel " appearance="fill">
                                <mat-label>Select Category</mat-label>
                                <mat-select [formControl]="toppings" multiple (ngModelChange)="categoryClass($event)"
                                    formControlName="categoryvalue">
                                    <mat-select-trigger>
                                        {{toppings.value?.[0] || ''}}
                                        <span *ngIf="(toppings.value?.length || 0) > 1"
                                            class="example-additional-selection" style="color:red">
                                            (+{{(toppings.value?.length || 0) - 1}} {{toppings.value?.length === 2 ?
                                            'other' : 'others'}})
                                        </span>
                                    </mat-select-trigger>
                                    <mat-option *ngFor="let categoryvalue of categories" [ngValue]="categoryvalue"
                                        [value]="categoryvalue"
                                        [selected]="categoryvalue.name">{{categoryvalue.name}}</mat-option>
                                </mat-select>
                            </mat-form-field> -->

                            <mat-form-field class="no-line  w-100 cmn-input c_multisel " appearance="fill">
                                <mat-select (selectionChange)="selectedCategory($event.value)"
                                    placeholder=" Select Category" formControlName="categoryvalue"
                                    [formControl]="toppings" [(value)]="categoryvalue.id" multiple>
                                    <mat-option *ngFor="let categoryvalue of categories; let i=index"
                                        [value]="categoryvalue.id">{{categoryvalue.name}}</mat-option>
                                </mat-select>
                            </mat-form-field>

                        </div>

                        <div class="mb-4 pb-1 position-relative">
                            <!-- <div class="position-relative btn-input">
                                <input type="text" class="form-control fs18 cmn-input input_60 ff_Roboto btn-input"
                                    placeholder="Tags">
                                <button class="red_btn btn px-3 py-0 shadow-none fs14 text-white fw-bold">ADD</button>
                            </div>
                            <div class="tags-edit d-flex align-items-center flex-wrap pt-1">
                                <span class="tag-itm fs14 color_pink fw-normal d-flex align-items-center">Muscular
                                    <img class="ms-2 cursor_p" src="assets/images/studiomodule/crossicn.svg"></span>
                                <span class="tag-itm fs14 color_pink fw-normal d-flex align-items-center">Brunette
                                    <img class="ms-2 cursor_p" src="assets/images/studiomodule/crossicn.svg"></span>
                                <span class="tag-itm fs14 color_pink fw-normal d-flex align-items-center">Muscular
                                    <img class="ms-2 cursor_p" src="assets/images/studiomodule/crossicn.svg"></span>
                                <span class="tag-itm fs14 color_pink fw-normal d-flex align-items-center">Natural
                                    Boobs <img class="ms-2 cursor_p"
                                        src="assets/images/studiomodule/crossicn.svg"></span>
                            </div> -->

                            <mat-chip-list #chipList aria-label="Fruit selection">
                                <input class="form-control fs18 cmn-input input_60 ff_Roboto " placeholder="Tags..."
                                    [matChipInputFor]="chipList" formControlName="tags"
                                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                    [matChipInputAddOnBlur]="addOnBlur" (matChipInputTokenEnd)="add($event)"
                                    (keydown)="checkEvent($event)" />
                                <button class="add_btn position-absolute" click="add($event)">Add</button>
                            </mat-chip-list>

                            <mat-chip class="mt-2 me-2 color_red" *ngFor="let clip of clipDetail"
                                [selectable]="selectable" [removable]="removable" (removed)="remove(clip)">
                                {{clip.name}}
                                <mat-icon class="" matChipRemove *ngIf="removable">

                                    <span class="tag_icon">
                                        <svg width="15" height="15" viewBox="0 0 15 15" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M7.33301 0.333984C9.18952 0.333984 10.97 1.07148 12.2828 2.38424C13.5955 3.69699 14.333 5.47747 14.333 7.33398C14.333 9.1905 13.5955 10.971 12.2828 12.2837C10.97 13.5965 9.18952 14.334 7.33301 14.334C5.47649 14.334 3.69601 13.5965 2.38326 12.2837C1.07051 10.971 0.333008 9.1905 0.333008 7.33398C0.333008 5.47747 1.07051 3.69699 2.38326 2.38424C3.69601 1.07148 5.47649 0.333984 7.33301 0.333984ZM7.33301 6.48498L5.70701 4.85898C5.59442 4.7464 5.44173 4.68315 5.28251 4.68315C5.12329 4.68315 4.97059 4.7464 4.85801 4.85898C4.74542 4.97157 4.68217 5.12427 4.68217 5.28348C4.68217 5.4427 4.74542 5.5954 4.85801 5.70798L6.48401 7.33398L4.85801 8.95998C4.80226 9.01573 4.75804 9.08191 4.72787 9.15475C4.6977 9.22758 4.68217 9.30565 4.68217 9.38448C4.68217 9.46332 4.6977 9.54139 4.72787 9.61422C4.75804 9.68706 4.80226 9.75324 4.85801 9.80898C4.91375 9.86473 4.97993 9.90895 5.05277 9.93912C5.12561 9.96929 5.20367 9.98482 5.28251 9.98482C5.36134 9.98482 5.43941 9.96929 5.51225 9.93912C5.58508 9.90895 5.65126 9.86473 5.70701 9.80898L7.33301 8.18298L8.95901 9.80898C9.01475 9.86473 9.08093 9.90895 9.15377 9.93912C9.22661 9.96929 9.30467 9.98482 9.38351 9.98482C9.46235 9.98482 9.54041 9.96929 9.61325 9.93912C9.68608 9.90895 9.75226 9.86473 9.80801 9.80898C9.86375 9.75324 9.90797 9.68706 9.93814 9.61422C9.96831 9.54139 9.98384 9.46332 9.98384 9.38448C9.98384 9.30565 9.96831 9.22758 9.93814 9.15475C9.90797 9.08191 9.86375 9.01573 9.80801 8.95998L8.18201 7.33398L9.80801 5.70798C9.86375 5.65224 9.90797 5.58606 9.93814 5.51322C9.96831 5.44039 9.98384 5.36232 9.98384 5.28348C9.98384 5.20465 9.96831 5.12658 9.93814 5.05375C9.90797 4.98091 9.86375 4.91473 9.80801 4.85898C9.75226 4.80324 9.68608 4.75902 9.61325 4.72885C9.54041 4.69868 9.46235 4.68315 9.38351 4.68315C9.30467 4.68315 9.22661 4.69868 9.15377 4.72885C9.08093 4.75902 9.01475 4.80324 8.95901 4.85898L7.33301 6.48498Z"
                                                fill="#fff" />
                                        </svg> </span> </mat-icon>
                            </mat-chip>
                        </div>

                        <div class="mb-4 pb-1">
                            <!-- <mat-form-field class="no-line  w-100 cmn-input c_multisel "  appearance="fill">
                                <mat-label>Select Models</mat-label>
                                <mat-select   [formControl]="toppings" multiple>
                                  <mat-select-trigger>
                                    {{toppings.value?.[0] || ''}}
                                    <span *ngIf="(toppings.value?.length || 0) > 1" class="example-additional-selection" style="color:red">
                                      (+{{(toppings.value?.length || 0) - 1}} {{toppings.value?.length === 2 ? 'other' : 'others'}})
                                    </span>
                                  </mat-select-trigger>
                                  <mat-option  *ngFor="let  modal of modalList" [value]=" modal">{{ modal}}</mat-option>
                                </mat-select>]]] -->
                            <!-- <mat-form-field class="no-line  w-100 cmn-input c_multisel " appearance="fill">
                                <mat-select (selectionChange)="selectedModel($event)" placeholder=" Select Model"
                                    formControlName="modelvalue" [formControl]="toppings" [(value)]="modelvalue.name"
                                    multiple>
                                    <mat-option *ngFor="let modelvalue of modelArray; let i=index">{{ modelvalue.name}}</mat-option>

                                    <mat-option [value]="modelvalue.name" *ngIf="modelArray.length==0" disabled selected
                                        hidden> Please add Model from model section..... </mat-option>
                                </mat-select>
                            </mat-form-field> -->
                            <mat-form-field class="no-line  w-100 cmn-input c_multisel " appearance="fill">
                                <mat-select (selectionChange)="selectedModel($event.value)"
                                    [placeholder]="modelArray.length==0?'Please add model from model section':'Select Model'"
                                    formControlName="modelvalue" [formControl]="toppings" [(value)]="modelvalue.id"
                                    multiple>
                                    <mat-option *ngFor="let modelvalue of modelArray; let i=index"
                                        [value]="modelvalue.id">{{modelvalue.name}}</mat-option>
                                </mat-select>
                            </mat-form-field>

                            <!-- </mat-form-field> -->
                            <!-- <select class="form-select fs18 cmn-input input_60 ff_Roboto" (keyup)="modelClass(0)"
                                [ngClass]="{'placeholder-color': modelvalue === 'Model'}"
                                (ngModelChange)="modelClass($event)" formControlName="modelvalue">
                                <option value="" *ngIf="modelArray.length!=0" disabled selected hidden>Select Model </option>
                                <option value="" *ngIf="modelArray.length==0" disabled selected hidden> Please add Model from model section.....  </option>

                                <option value="{{modelvalue}}"  [selected]="modelvalue.name" [ngValue]="modelvalue"
                                    *ngFor="let modelvalue of modelArray; let i=index;">{{modelvalue.name}}</option>

                            </select> -->
                        </div>
                        <div class="mb-4 pb-1">
                            <div class="priceinput position-relative">
                                <!-- <span class="fs18 fw-normal text-white dlr"></span> -->
                                <input type="text" class="form-control fs18 cmn-input input_60 ff_Roboto ps-4"
                                     placeholder="Enter video length like : 29 minutes or 29 seconds." value="15.25"
                                    formControlName="duration" (keydown)="checkEvent($event)">
                            </div>
                            <div *ngIf="addClipForm.get('duration')?.hasError('invalidFormat')" class="text-danger">
                               Note- Use the format like: "29 minutes" or "29 seconds".
                              </div>
                        </div>
                        <div class="mb-4 pb-1">
                            <textarea class="cmn-textarea form-control fs18" rows="4" placeholder=" Video Description"
                                formControlName="description" (keydown)="checkEvent($event)"></textarea>
                        </div>
                        <button class="btn w-100 purple_btn btn70 fw-bold"
                            [disabled]="(addClipForm.get('title')?.invalid|| addClipForm.get('price')?.invalid|| addClipForm.get('categories')?.invalid || addClipForm.get('uploadVideo')?.invalid || addClipForm.get('uploadCoverImage')?.invalid|| addClipForm.get('uploadTrailer')?.invalid )"
                            (click)="addClip()">Add</button>
                    </div>
                </div>


                <div class="col-12 mt-4">

                    <div class="file_guidline mt-3 ">

                        <h5> File Guidelines</h5>

                        <div class="guidline_list">
                            <p class="mt-3"> <span class="file_head color_purple fw-bold "> Thumbnail Size : </span>
                                File Must not exceed 3 MP and no longer then 240x 160 pixels </p>

                            <p class=""> <span class="file_head color_purple fw-bold "> Video Clip Files : </span> File
                                Must not exceed 3 MP and no longer then 240x 160 pixels </p>

                            <p class=""> <span class="file_head color_purple fw-bold "> Cover Photo : </span> Gif ,JPEG
                                only.thumb Max size 3 MB and No larger then 240x160 </p>
                        </div>
                    </div>

                </div>

            </div>
        </div>
    </form>
</section>
<!-- <style type="text/css">@import url(resources/scripts/plupload/jquery.plupload.queue/css/jquery.plupload.queue.css);</style>
<script type="text/javascript" src="resources/scripts/jquery-1.7.2.min.js"></script>

<script type="text/javascript" src="resources/scripts/plupload/plupload.full.js"></script>
<script type="text/javascript" src="resources/scripts/plupload/jquery.plupload.queue/jquery.plupload.queue.js"></script> -->

<app-studio-footer> </app-studio-footer>