<section class="header">
  <div class="container">
    <nav class="navbar navbar-expand-lg">
      <a class="navbar-brand py-0"><img src="assets/images/studiomodule/logo.png"></a>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav mx-auto mb-2 mb-lg-0">
          <li class="nav-item">
            <a class="nav-link text-uppercase ff_smooch" aria-current="page" [routerLink]="['/studio-home']"
              [routerLinkActive]="['active']">Revenue</a>
          </li>
          <li class="nav-item">
            <a class="nav-link text-uppercase ff_smooch" [routerLink]="['/studio-clips']"
              [routerLinkActive]="['active']">Clips</a>
          </li>
          <li class="nav-item">
            <a class="nav-link text-uppercase ff_smooch" [routerLink]="['/studio-models']"
              [routerLinkActive]="['active']">Models</a>
          </li>
        </ul>
        <div class="nav-item dropdown">
          <a class="nav-link ff_Roboto d-flex align-items-center pe-0" href="#" id="navbarDropdown" role="button"
            data-bs-toggle="dropdown" aria-expanded="false">
            <svg style="margin-right: 10px;" width="20" height="20" viewBox="0 0 20 20" fill="none">
              <path
                d="M10.4166 0C9.36364 0 8.33429 0.308679 7.45875 0.887002C6.58321 1.46533 5.90081 2.28732 5.49784 3.24903C5.09488 4.21075 4.98944 5.269 5.19487 6.28995C5.4003 7.3109 5.90737 8.24871 6.65196 8.98477C7.39654 9.72084 8.3452 10.2221 9.37797 10.4252C10.4107 10.6283 11.4812 10.524 12.4541 10.1257C13.4269 9.72733 14.2584 9.05273 14.8435 8.18721C15.4285 7.32169 15.7407 6.30411 15.7407 5.26316C15.7407 3.86728 15.1798 2.52858 14.1813 1.54154C13.1829 0.55451 11.8287 0 10.4166 0ZM10.4166 8.42105C9.78484 8.42105 9.16723 8.23585 8.64191 7.88885C8.11658 7.54186 7.70714 7.04866 7.46536 6.47163C7.22358 5.8946 7.16032 5.25965 7.28358 4.64708C7.40684 4.03451 7.71108 3.47183 8.15783 3.03019C8.60458 2.58855 9.17378 2.28779 9.79344 2.16594C10.4131 2.04409 11.0554 2.10663 11.6391 2.34564C12.2228 2.58466 12.7217 2.98941 13.0727 3.50873C13.4237 4.02804 13.6111 4.63859 13.6111 5.26316C13.6111 6.10068 13.2745 6.90391 12.6755 7.49613C12.0764 8.08835 11.2639 8.42105 10.4166 8.42105ZM20 20V18.9474C20 16.9931 19.2147 15.119 17.8168 13.7371C16.419 12.3553 14.5231 11.5789 12.5463 11.5789H8.28702C6.31017 11.5789 4.41429 12.3553 3.01645 13.7371C1.61861 15.119 0.833313 16.9931 0.833313 18.9474V20H2.96294V18.9474C2.96294 17.5515 3.52387 16.2128 4.52233 15.2258C5.52079 14.2387 6.87498 13.6842 8.28702 13.6842H12.5463C13.9583 13.6842 15.3125 14.2387 16.311 15.2258C17.3094 16.2128 17.8703 17.5515 17.8703 18.9474V20H20Z"
                fill="#F8F8F8" />
            </svg>
            {{studioName}}
            <svg style="margin-left: 6px" width="10" height="10" viewBox="0 0 10 10" fill="none">
              <path d="M1 4L5 8L9 4" stroke="#F8F8F8" stroke-width="1.5" stroke-linecap="round"
                stroke-linejoin="round" />
            </svg>
          </a>
          <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
            <li><a class="dropdown-item text-uppercase ff_smooch d-flex align-items-center"
                [routerLink]="['/studio-my-profile']">
                <svg class="me-3" width="15" height="15" viewBox="0 0 15 15" fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M7.1875 0C6.39775 0 5.62573 0.231509 4.96908 0.665251C4.31242 1.09899 3.80062 1.71549 3.4984 2.43678C3.19617 3.15806 3.1171 3.95175 3.27117 4.71746C3.42524 5.48318 3.80554 6.18653 4.36398 6.73858C4.92242 7.29063 5.63392 7.66658 6.40849 7.81889C7.18307 7.9712 7.98594 7.89303 8.71558 7.59426C9.44521 7.2955 10.0688 6.78955 10.5076 6.14041C10.9464 5.49127 11.1806 4.72808 11.1806 3.94737C11.1806 2.90046 10.7599 1.89643 10.011 1.15616C9.26217 0.415882 8.24652 0 7.1875 0ZM7.1875 6.31579C6.71365 6.31579 6.25044 6.17688 5.85645 5.91664C5.46245 5.65639 5.15537 5.2865 4.97404 4.85372C4.7927 4.42095 4.74526 3.94474 4.8377 3.48531C4.93015 3.02588 5.15833 2.60387 5.49339 2.27264C5.82845 1.94141 6.25535 1.71584 6.7201 1.62446C7.18484 1.53307 7.66656 1.57997 8.10435 1.75923C8.54213 1.93849 8.9163 2.24206 9.17956 2.63154C9.44282 3.02103 9.58333 3.47894 9.58333 3.94737C9.58333 4.57551 9.33092 5.17793 8.88161 5.6221C8.4323 6.06626 7.82291 6.31579 7.1875 6.31579ZM14.375 15V14.2105C14.375 12.7449 13.786 11.3392 12.7376 10.3028C11.6893 9.26645 10.2674 8.68421 8.78472 8.68421H5.59028C4.10764 8.68421 2.68573 9.26645 1.63735 10.3028C0.588974 11.3392 0 12.7449 0 14.2105V15H1.59722V14.2105C1.59722 13.1636 2.01792 12.1596 2.76676 11.4193C3.5156 10.679 4.53125 10.2632 5.59028 10.2632H8.78472C9.84375 10.2632 10.8594 10.679 11.6082 11.4193C12.3571 12.1596 12.7778 13.1636 12.7778 14.2105V15H14.375Z"
                    fill="#F8F8F8" />
                </svg>
                My Profile
              </a></li>
            <li><a class="dropdown-item text-uppercase ff_smooch d-flex align-items-center" (click)="logOut()">
                <svg class="me-3" width="16" height="16" viewBox="0 0 16 16" fill="none">
                  <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M1 1H8.95816V2.15385H2.13688V14.8462H8.95816V16H1V1ZM12.2028 4.63L16 8.48385L12.2176 12.6412L11.3831 11.8588L13.9127 9.07692H4.41064V7.92308H13.8388L11.3979 5.44692L12.2028 4.63Z"
                    fill="#F8F8F8" />
                </svg>
                Logout
              </a></li>
          </ul>
        </div>
      </div>
    </nav>
  </div>
</section>