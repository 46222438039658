<app-header></app-header>

<section class="my-profile py-5">
    <div class="container">
        <div class="row d-flex justify-content-center">
            <div class="col-md-7">
                <div class="profile_sec text-center mb-5">
                    <div class="studio-img mb-4 p-0">
                        <img class="fit-img" [src]="logoData" alt="">
                    </div>
                    <h3>{{profileData?.name}}</h3>
                    <p class="color_grey fs20">{{profileData?.email}}</p>
                </div>
                <div class="black-card">
                    <div class="user-details p-4 mb-3">
                        <div class="d-flex justify-content-between align-items-center mb-4">
                            <h3 class="fs18 text-uppercase fw-medium mb-0">User Info</h3>
                            <button class="btn grey_btn small fw-medium shadow-none px-3 py-2 lh1"
                                [routerLink]="['/studio-edit-profile']">Edit</button>
                        </div>
                       

                        <div class="info-row mb-4 pb-2">
                            <div class="text-sec">
                                <h6 class="small color_grey fw-normal mb-2">Studio name</h6>
                                <p class="mb-0">{{profileData?.name}}</p>
                            </div>
                        </div>

                        <div class="info-row mb-4 pb-2">
                            <div class="text-sec">
                                <h6 class="small color_grey fw-normal mb-2">Studio Description</h6>
                                <p class="mb-0">{{profileData?.description}}</p>
                            </div>
                        </div>

                        <div class="info-row mb-4 pb-2">
                            <div class="text-sec">
                                <h6 class="small color_grey fw-normal mb-2">Email</h6>
                                <p class="mb-0">{{profileData?.email}}</p>
                            </div>
                        </div>

                        <div class="info-row mb-4 pb-2">
                            <div class="text-sec">
                                <h6 class="small color_grey fw-normal mb-2">Phone number</h6>
                                <p class="mb-0">{{profileData?.phoneNumber}}</p>
                            </div>
                        </div>

                        <div class="info-row mb-4 pb-2">
                            <div class="text-sec">
                                <h6 class="small color_grey fw-normal mb-2">Website</h6>
                                <p class="mb-0">{{profileData?.website}}</p>
                            </div>
                        </div>

                        <div class="info-row mb-4 pb-2">
                            <div class="text-sec">
                                <h6 class="small color_grey fw-normal mb-2">Social Security Number</h6>
                                <p class="mb-0">{{profileData?.ssn}}</p>
                            </div>
                        </div>

                        <div class="info-row mb-4 pb-2">
                            <div class="text-sec">
                                <h6 class="small color_grey fw-normal mb-2">Legal Business Name</h6>
                                <p class="mb-0">{{profileData?.fax}}</p>
                            </div>
                        </div>

                        <!-- <div class="info-row d-flex align-items-end justify-content-between mb-3">
                            <div class="text-sec">
                                <h6 class="small color_grey fw-normal mb-2">Password</h6>
                                <p class="mb-0">*******</p>
                            </div>
                            <button class="btn grey_btn small fw-medium shadow-none px-3 py-2 lh1"
                                [routerLink]="['/studio-change-password']">Change</button>
                        </div> -->
                    </div>
                    <div class="payment-details p-4">
                        <div class="d-flex justify-content-between align-items-center mb-3">
                            <h3 class="fs18 text-uppercase fw-medium mb-0">Address</h3>
                            <button class="btn grey_btn small fw-medium shadow-none px-3 py-2 lh1"
                                [routerLink]="['/studio-change-address']">Edit</button>
                        </div>
                        <div class="info-row d-flex align-items-end justify-content-between">
                            <div class="no-sec ">
                                <p class="mb-0 ">
                                    {{profileData?.address}} , {{profileData?.city}} , {{profileData?.state}} , {{profileData?.country}} , {{profileData?.zipcode}}
                                </p>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>



<!-- <section class="my-profile py-5">
    <div class="container">
        <div class="row">
            <div class="col-md-6">
                <div class="inner-sec pe-lg-5 pe-0">
                    <div class="logo-sec text-center">
                        <div class="img-wrap mx-auto d-flex align-items-center justify-content-center">
                            <img class="img-fluid" src="assets/images/dummyimg.svg">
                        </div>
                        <p class="mt-4 upload-sec fs18 mb-0">
                            <span class="position-relative d-inline-block">
                                Upload Studio Logo
                                <input type="file" class="upload_input">
                            </span>
                        </p>
                    </div>
                    <div class="licence-sec">
                        <div class="img-wrap d-flex align-items-center justify-content-center flex-column position-relative">
                            <img class="img-fluid" src="assets/images/uploadicn.svg">
                            <p class="fs18 text-center mb-0 mt-3 pt-1">Upload Licence</p>
                            <input type="file" class="upload_input">
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-sec">
                    <form>
                        <div class="inner-sec ">
                            <div class="mb-4 pb-1">
                                <label class="form-label fs18 mb-2 pb-1 text-white fw-normal ff_Roboto">Studio Name</label>
                                <input type="text" class="form-control fs18 cmn-input input_60 ff_Roboto" value="Abc store" placeholder="Enter studio name">
                             </div>
                             <div class="mb-4 pb-1">
                               <label class="form-label fs18 mb-2 pb-1 text-white fw-normal ff_Roboto">Email Address</label>
                               <input type="email" class="form-control fs18 cmn-input input_60 ff_Roboto" value="example@gmail.com" placeholder="example@gmail.com">
                             </div>
                    
                             <div class="mb-4 pb-1">
                               <label class="form-label fs18 mb-2 pb-1 text-white fw-normal ff_Roboto">Phone Number</label>
                               <input type="tel" class="form-control fs18 cmn-input input_60 ff_Roboto" placeholder="Enter phone number" value="0123654789">
                             </div>
        
                             <div class="mb-4 pb-1">
                                <label class="form-label fs18 mb-2 pb-1 text-white fw-normal ff_Roboto">Password</label>
                                <input type="password" class="form-control fs18 cmn-input input_60 ff_Roboto" placeholder="Enter phone number" value="Test@123">
                              </div>
                    
                             <div class="mb-5">
                                <label class="form-label fs18 mb-2 pb-1 text-white fw-normal ff_Roboto">Confirm Password</label>
                                <input type="password" class="form-control fs18 cmn-input input_60 ff_Roboto" placeholder="Enter confirm password" value="Test@123">
                            </div>
                    
                            <button class="btn w-100 purple_btn btn70 fw-bold">Edit Profile</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section> -->

<app-studio-footer> </app-studio-footer>