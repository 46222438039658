import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/common/api-service/api.service';
import { MessagehandlingService } from 'src/app/common/message/messagehandling.service';
import { COMMA, ENTER } from '@angular/cdk/keycodes';

@Component({
  selector: 'app-add-model',
  templateUrl: './add-model.component.html',
  styleUrls: ['./add-model.component.scss']
})
export class AddModelComponent implements OnInit {
  addModal: FormGroup
  gendervalue: any = "Gender"
  modalProfile: any
  modalProfileBlob: any;
  availCustom: boolean = false


  value: any;
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  modelData: any[] = [];
  profile: any;
  licenceData: any;


  constructor(private formBuilder: FormBuilder, private router: Router, private api: ApiService, private message: MessagehandlingService) {

    this.addModal = this.formBuilder.group({
      username: new FormControl('', Validators.required),
      firstname: new FormControl('', [Validators.required]),
      profileImage: new FormControl(''),
      tags: new FormControl(''),
      bio: new FormControl(''),
      gendervalue: new FormControl('', Validators.required),
      availCustom: new FormControl(''),


    })

  }

  ngOnInit(): void {
  }

  genderClass(event: any) {
      this.gendervalue = event;
  }

  checkEvent(event: any) {
 if (event.target.value == '' && event.which == 32) {
      event.preventDefault();
    }
  }

  customChange(event: any) {
    console.log(this.availCustom, event)

  }


  commaSaperatedTags(data: any[]) {
    let saperated = '';
    if (data.length) {
      data.forEach(element => {
        saperated = saperated + (saperated.length ? ',' : '') + element.name
      });
    }
    return saperated;
  }


  add(event: MatChipInputEvent): void {

    const input = event.input;
    this.value = event.value;

    // Add our fruit
    if ((this.value || '').trim()) {

      this.modelData.push({ id: '0', name: this.value.trim() });
      // this.modelData.push(this.value);
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }
  }

  remove(model: any): void {
    const index = this.modelData.indexOf(model);

    if (index >= 0) {
      this.modelData.splice(index, 1);
    }
  }


  onFileChange(event: any): void {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      let reader = new FileReader();
      reader.onload = e => {
        this.licenceData = reader.result
      }
      reader.readAsDataURL(file);
      this.profile = file
    }
  }

  addModelData() {
  //   
      // let fd = {
      //   tags: this.modelData,
      //   username: this.addModal.value.username,
      //   name: this.addModal.value.firstname,
      //   bio: this.addModal.value.bio,
      //   gender: this.addModal.value.gendervalue,
      //   isCustom: this.addModal.value.availCustom,
      //   profile: this.profile
      // }
      if (this.addModal.value.username=='' || this.addModal.value.firstname=='' || this.addModal.value.gendervalue=='' || this.addModal.value.gendervalue=='Gender' ||this.addModal.value.profileImage=='' ) {
        alert("Please add Image and Enter Name , Username and Gender. These fields are mandatory ")
      }else{
        setTimeout(() => {
      let fd = new FormData
      fd.append('profile', this.profile)
      fd.append('username', this.addModal.value.username)
      fd.append('name', this.addModal.value.firstname)
      fd.append('bio', this.addModal.value.bio)
      fd.append('gender', this.addModal.value.gendervalue)
      fd.append('isCustom', this.addModal.value.availCustom == false ? '0' : '1')
      fd.append('tags', JSON.stringify(this.modelData))
      this.api.addModel(fd).subscribe((res: any) => {
        if (res) {
          // this.message.sucessMessage(res.message, 2000)
          this.router.navigate(['/studio-models'])
        }

      })
    }, 2000);
      }
  }




}



