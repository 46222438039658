<!-- <app-header></app-header>

<section class="breadcrumb-banner">
    <div class="container">
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item ps-0 active" [routerLink]="['/home']">Home</li>
                <li class="breadcrumb-item active" [routerLink]="['/add-token']">Add Token</li>
                <li class="breadcrumb-item">Add Card</li>
            </ol>
        </nav>
    </div>
</section> -->

<section class="add-token-sec sec_padding_bottom">
    <div class="container">
        <div class="main-box mt-4 p-5">
            <div class="row">
                <div class="col-md-6">
                    <div class="img-wrap d-flex align-items-center justify-content-center">
                        <img src="assets/images/add-token-img.svg">
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-sec">
                        <form [formGroup]="addCardForm">
                            <div class="mb-4 pb-1">
                                <label
                                    class="form-label text-uppercase fs18 mb-2 pb-1 text-white fw-normal ff_Roboto">Card
                                    Number</label>
                                <input type="text" class="form-control fs18 cmn-input input_60 ff_Roboto" maxlength="16"
                                    (keydown)="checkEvent($event)" (keypress)="numberOnly($event)"
                                    placeholder="Enter your 16 digit card number" formControlName="cardNumber">
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <div class="mb-4 pb-1">
                                        <label class="form-label text-uppercase fs18 mb-2 pb-1 text-white fw-normal ff_Roboto">Valid Thru</label>
                                        <input type="text" class="form-control fs18 cmn-input input_60 ff_Roboto" 
                                               placeholder="MM/YYYY" formControlName="expDate">
                                        <div *ngIf="expirationDateError?.invalidExpirationDate" class="text-danger">Expiration date format like(MM/YYYY).</div>
                                      </div>

                                </div>
                                <!-- <div class="col-4">
                                    <div class="mb-4 pb-1">
                                        <label
                                            class="form-label text-uppercase fs18 mb-2 pb-1 text-white fw-normal ff_Roboto">CVV</label>
                                        <input type="text" class="form-control fs18 cmn-input input_60 ff_Roboto"
                                            placeholder="3 Digit Code" maxlength="3" (keydown)="checkEvent($event)"
                                            (keypress)="numberOnly($event)">
                                    </div>
                                </div> -->
                            </div>
                            <div class="mb-4 pb-1">
                                <label
                                    class="form-label text-uppercase fs18 mb-2 pb-1 text-white fw-normal ff_Roboto">Name
                                    on card</label>
                                <input type="text" class="form-control fs18 cmn-input input_60 ff_Roboto"
                                    placeholder="Enter Name" (keydown)="checkEvent($event)" formControlName="cardHolderName">
                            </div>
                            <div class="mb-4 pb-1">
                                <label
                                    class="form-label text-uppercase fs18 mb-2 pb-1 text-white fw-normal ff_Roboto">Address</label>
                                <input type="text" class="form-control fs18 cmn-input input_60 ff_Roboto"
                                    placeholder="Enter address" (keydown)="checkEvent($event)" formControlName="address">
                            </div>
                            <div class="row">
                                <div class="col-6">
                                    <div class="mb-4 pb-1">
                                        <label
                                            class="form-label text-uppercase fs18 mb-2 pb-1 text-white fw-normal ff_Roboto">city
                                            </label>
                                        <input type="text" class="form-control fs18 cmn-input input_60 ff_Roboto"
                                            formControlName="city" placeholder="Enter city">
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="mb-4 pb-1">
                                        <label
                                            class="form-label text-uppercase fs18 mb-2 pb-1 text-white fw-normal ff_Roboto">State</label>
                                        <input type="text" class="form-control fs18 cmn-input input_60 ff_Roboto"
                                            placeholder="Enter state" (keydown)="checkEvent($event)"
                                            formControlName="state">
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-6">
                                    <div class="mb-4 pb-1">
                                        <label
                                            class="form-label text-uppercase fs18 mb-2 pb-1 text-white fw-normal ff_Roboto">Zip code
                                            </label>
                                        <input type="text" class="form-control fs18 cmn-input input_60 ff_Roboto"
                                            formControlName="zipcode" placeholder="Enter zip code">
                                        <!-- <input type="text" [value]="formattedMonthAndYear" readonly  placeholder="MM/YY"> -->
                                        <!-- <input type="month" class="form-control fs18 cmn-input input_60 ff_Roboto"
                                            id="monthYearInput" [(ngModel)]="selectedMonthAndYear"
                                            [ngModelOptions]="{standalone: true}"
                                            (ngModelChange)="onMonthYearSelected()" placeholder="MM/YY">
                                        <input type="text" [value]="formattedMonthAndYear" readonly  placeholder="MM/YY"> -->
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="mb-4 pb-1">
                                        <label
                                            class="form-label text-uppercase fs18 mb-2 pb-1 text-white fw-normal ff_Roboto">Country</label>
                                        <input type="text" class="form-control fs18 cmn-input input_60 ff_Roboto"
                                            placeholder="Enter country" (keydown)="checkEvent($event)"
                                            formControlName="country">
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="mb-4 pb-3">
                                <mat-checkbox color="primary">Save card for future payments</mat-checkbox>
                            </div> -->

                            <button class="btn w-100 purple_btn btn70 fw-bold" (click)="saveDetails()">Save Details</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- <app-footer></app-footer> -->