import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';
import { MessagehandlingService } from '../message/messagehandling.service';


@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    public message: MessagehandlingService,
    private spinner: NgxSpinnerService
    ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    this.spinner.show();
    return next.handle(request).pipe(catchError(error => {
    
      this.spinner.hide();
     
      switch (error.status) {
        case 401:
       
          /********** Auto logout if 401 response returned from api **********/
          this.message.errorMessage(error.statusText, 2000)
          break;
        case 400:
          /********** handle error msg **********/
          this.message.errorMessage(error.statusText, 2000)
          break;

          case 0:
            /********** If server dosent respond **********/
            this.message.errorMessage("Response is not coming from API", 2000)
            break;

        default:
          /********** Check for other serve-side errors **********/
          this.message.errorMessage(error.statusText, 2000)
          break;
      }
        return throwError(error);
    }));
  }
}
