import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SignupComponent } from './interface/member/components/signup/signup.component';
import { ForgotPasswordComponent } from './interface/member/components/forgot-password/forgot-password.component';
import { AboutUsComponent } from './interface/member/components/about-us/about-us.component';
import { ContactUsComponent } from './interface/member/components/contact-us/contact-us.component';
import { VideoDetailComponent } from './interface/member/components/video-detail/video-detail.component';
import { ModelDetailsComponent } from './interface/member/components/model-details/model-details.component';
import { CategoryListComponent } from './interface/member/components/category-list/category-list.component';
import { PurchasedVideoComponent } from './interface/member/components/purchased-video/purchased-video.component';
import { ModelsComponent } from './interface/member/components/models/models.component';
import { MyProfileComponent } from './interface/member/components/my-profile/my-profile.component';
import { EditNameComponent } from './interface/member/components/edit-name/edit-name.component';
import { EditEmailComponent } from './interface/member/components/edit-email/edit-email.component';
import { VerifyEmailComponent } from './interface/member/components/verify-email/verify-email.component';
import { ChangePasswordComponent } from './interface/member/components/change-password/change-password.component';
import { EditPaymentComponent } from './interface/member/components/edit-payment/edit-payment.component';
import { EditCardComponent } from './interface/member/components/edit-card/edit-card.component';
import { StudiosComponent } from './interface/member/components/studios/studios.component';
import { CartComponent } from './interface/member/components/cart/cart.component';
import { AddTokenComponent } from './interface/member/components/add-token/add-token.component';
import { AllCategoriesComponent } from './interface/member/components/all-categories/all-categories.component';
import { DisclaimerComponent } from './interface/member/components/disclaimer/disclaimer.component';
import { StudioSignupComponent } from './interface/member/components/studio-signup/studio-signup.component';
import { NotificationComponent } from './interface/member/components/notification/notification.component';
import { StudioDetailsComponent } from './interface/member/components/studio-details/studio-details.component';
import { SeeAllModelsComponent } from './interface/member/components/see-all-models/see-all-models.component';
import { SeeAllVideosComponent } from './interface/member/components/see-all-videos/see-all-videos.component';
import { HomeComponent } from './interface/member/components/home/home.component';
import { LandingPageComponent } from './interface/common/landing-page/landing-page.component';
import { LoginComponent } from './interface/member/components/login/login.component';



const routes: Routes = [
  // {path: '', component: DisclaimerComponent},
  // {path: 'landingpage', component: LandingPageComponent},
  { path: 'members', loadChildren: () => import('./interface/member/member.module').then(m => m.MemberModule) },
  // { path: 'studio', loadChildren: () => import('./interface/studio/studio.module').then(m => m.StudioModule) },

];

@NgModule({
  imports: [RouterModule.forRoot(routes,{useHash:false})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
