<section class="login-sec member_sec d-flex align-items-center justify-content-center flex-column ">

    <h2 class="ff_kalam font-normal  become_member fs54 text-white mb-5 ">Become A Member</h2>


    <div class="content-box p-0 ">
        <div class="top-sec d-flex align-items-center justify-content-between ">

            <div>
                <h3 class="fw-normal mb-0"> Monthly </h3>
                <p class="small">Membership</p>
            </div>

            <div class="right-sec">
                <h3 class="mb-2 ">$15.00</h3>

            </div>
        </div>

        <hr class="mt-0">

        <div class=" sub_points mt-5">

            <ul>


                <li class="d-flex align-items-center">
                    <span class="icon me-3"> <svg width="16" height="15" viewBox="0 0 16 15" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <circle cx="8.21338" cy="7.5" r="7.5" fill="white" />
                            <path d="M3.71338 7.5L7.08838 10.5L12.7134 4.5" stroke="#FF2062" stroke-width="2" />
                        </svg>
                    </span>
                    <span>Monthly Subscription will allow you to buy the videos.</span>



                </li>


                <li class="d-flex align-items-center mt-4">
                    <span class="icon me-3"> <svg width="16" height="15" viewBox="0 0 16 15" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <circle cx="8.21338" cy="7.5" r="7.5" fill="white" />
                            <path d="M3.71338 7.5L7.08838 10.5L12.7134 4.5" stroke="#FF2062" stroke-width="2" />
                        </svg>
                    </span>
                    <span> You will get equalized tokens to the amount paid. </span>



                </li>
                <li class="d-flex align-items-center mt-4">
                    <span class="icon me-3"> <svg width="16" height="15" viewBox="0 0 16 15" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <circle cx="8.21338" cy="7.5" r="7.5" fill="white" />
                            <path d="M3.71338 7.5L7.08838 10.5L12.7134 4.5" stroke="#FF2062" stroke-width="2" />
                        </svg>
                    </span>
                    <span>You will be rewarded with 5 extra tokens to purchase the choke chamber videos.</span>



                </li>
                <li class="d-flex align-items-center mt-4">
                    <span class="icon me-3"> <svg width="16" height="15" viewBox="0 0 16 15" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <circle cx="8.21338" cy="7.5" r="7.5" fill="white" />
                            <path d="M3.71338 7.5L7.08838 10.5L12.7134 4.5" stroke="#FF2062" stroke-width="2" />
                        </svg>
                    </span>
                    <span>The subscription will be recurring but you can cancel it anytime.</span>



                </li>
                <li class="d-flex align-items-center mt-4">
                    <span class="icon me-3"> <svg width="16" height="15" viewBox="0 0 16 15" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <circle cx="8.21338" cy="7.5" r="7.5" fill="white" />
                            <path d="M3.71338 7.5L7.08838 10.5L12.7134 4.5" stroke="#FF2062" stroke-width="2" />
                        </svg>
                    </span>
                    <span>You can be rewarded with extra tokens by your Admin.</span>



                </li>

            </ul>


            <div class="btn_group ">
                <!-- <button class="btn fs20 btn70 fw-bold btn btn-outline-light" type="submit" [routerLink]="['/landingpage']">Skip</button> -->
                <button class="btn   purple_btn btn70 fw-bold" type="submit"  (click)="buyPlan()">Buy Plan</button>
            </div>

        </div>



    </div>
</section>