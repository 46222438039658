import { Component, OnInit } from '@angular/core';
import { Route, Router } from '@angular/router';

@Component({
  selector: 'app-membership',
  templateUrl: './membership.component.html',
  styleUrls: ['./membership.component.scss']
})
export class MembershipComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  buyPlan() {
    this.router.navigate(['/card-detail'])
  }

}
