<section class="disclaimer-sec sec_padding">
    <div class="container">
        <div class="logo-wrap text-center sec_padding_bottom">
            <img src="assets/images/d-logo.png">
        </div>

        <div class="content_box p-4">
            <h2 class="fw-normal ff_kalam mb-2">Disclaimer</h2>
            <p class="mb-4 fw-light">This site is intended for Adults ONLY!<br>If you are not over 18, please leave now.
            </p>

            <h2 class="fw-normal ff_kalam mb-2 pt-1">Warning</h2>
            <p class="mb-3 fw-light">Chokechamber films and our partners have one goal… to titillate, excite, entertain,
                and keep our erotic horror fans engaged, by producing high quality, original, creative death fantasy
                horror media. Our site will have scenes that are sexual in nature containing simulated violence, nudity,
                blood, and adult situations that may offend some viewers. The content of this website is inappropriate
                for anyone under 18. Viewer discretion is strongly advised.</p>
            <p class="mb-3 fw-light">All participants and/or characters depicted in these videos are all paid
                professional actors, and ae all over the legal age of 18 during time of production. All scenes are
                fictitious, a movie, purely “simulated,” in other words… NOT REAL. For entertainment purposes only. No
                person or animal was or will ever be harmed during the making of any Chokechamber or Vixen Horror House
                production. WE DO NOT CONDONE, SUPPORT, OR ENCOURAGE ANY REAL VIOLENCE AGAINST ANY PERSON, OR ANIMAL. In
                fact, we love all animals, support women's rights, and respect men. </p>
            <p class="mb-3 fw-light">Any case, real or not, content in this site may offend some viewers. If you're not
                into this kind of media, and /or under the age of 18, please leave now. By entering this site, you are
                making a legal statement that you are an adult, over the age of 18 and that you want to view this
                material, and that it is legal for you to do so in your community, state, province or nation. By
                proceeding you assume full responsibility for your actions.</p>
            <p class="mb-3 fw-light"><span class="text-uppercase fs20 fw-medium color_pink">ICRA</span> protect your
                children<br>These web pages are not intended to be viewed by minors.<br>Labeled by IRCA -
                http://www.irca.org</p>
            <p class="mb-4 fw-light">Each web page available on this site is self-rated with ICRA labeling, which is
                built into Microsoft Internet Explorer's Parental Controls. If you are a parent and you want to block
                this site, please enable MSIE parental controls.</p>

            <h2 class="fw-normal ff_kalam mb-2 pt-1">Verify Your Age</h2>
            <div class="row">
                <!-- <div class="col-md-6">
                    <form>
                        <div class="mb-4 pb-1 pt-2">
                            <label class="form-label fs18 mb-2 pb-1 text-white fw-normal ff_Roboto">Date of birth</label>
                          <input type="date" class="form-control fs18 cmn-input input_60 ff_Roboto" placeholder="DD/MM/YYYY" [ngModel]="theDate | date:'yyyy-MM-dd'" name="theDate"
                          (ngModelChange)="theDate = $event">
                        </div>
                        <button type="button" class="btn w-100 purple_btn btn70 fw-bold" (click)="verifyAge(theDate)">Verify Age</button>
                      </form>
                </div> -->
                <div class="col-md-6">
                    <form [formGroup]="myForm">
                        <div class="mb-4 pb-1 pt-2">
                            <label class="form-label fs18 mb-2 pb-1 text-white fw-normal ff_Roboto">Date of
                                birth</label>
                            <!-- Regular text input with date picker -->
                            <input type="text" class="form-control fs18 cmn-input input_60 ff_Roboto"
                                placeholder="MM-DD-YYYY" formControlName="theDate" bsDatepicker>
                        </div>
                        <div class="mb-4 error-message" *ngIf="!myForm.controls?.['theDate']?.hasError('pattern')">
                            Please enter a valid date (MM-DD-YYYY).
                        </div>
                        <!-- <p class="formatted-date">{{ myForm.get('theDate')?.value | date: 'dd-MM-yyyy' }}</p> -->
                        <button type="button" class="btn w-100 purple_btn btn70 fw-bold"
                            (click)="verifyAge(myForm.get('theDate')?.value)">Verify Age</button>
                    </form>
                </div>
                  
                  
                  
                  
                  
                  

            </div>
        </div>
    </div>
</section>