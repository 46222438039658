import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { IVideoConfig } from "ngx-video-list-player";
import { ApiService } from 'src/app/common/api-service/api.service';
import { MessagehandlingService } from 'src/app/common/message/messagehandling.service';
declare const $: any;
@Component({
  selector: 'app-video-detail',
  templateUrl: './video-detail.component.html',
  styleUrls: ['./video-detail.component.scss']
})
export class VideoDetailComponent implements OnInit {
  id: any
  config: IVideoConfig = {
    sources: [
      {
        src: 'assets/videos/demovedio.mp4',
      },
      {
        src: 'assets/videos/demovedio1.mp4',
      }]
  };
  clipDetail: any;
  tags: any;
  relatedClips: any;
  videoData: any;
  clipUrl: any;
  time = new Date()
  constructor(private router: Router, public activeroute: ActivatedRoute, private api: ApiService, private message: MessagehandlingService) {
    this.activeroute.params.subscribe((params: any) => {
      this.id = params['id'];
      if (this.id) {
        // this.getDetail(this.id)
        this.videoDetail(this.id)
      } else {
        this.router.navigate(['/']);
      }
    })
  }

  ngOnInit(): void {

  }
  fav(id: any, isFav: any) {
    
    let fd = new FormData

    fd.append('CID', id)
    if (isFav == 1) {
      fd.append('status', "0")
      $(".likeContainer").toggleClass("heart_filled");
    }
    else {
      fd.append('status', "1")

      $(".likeContainer").removeClass("heart_filled");

    }
    this.api.addFavourite(fd).subscribe((res: any) => {
      this.message.sucessMessage(res.message, 1000)
      this.videoDetail(this.id)


    })
    // $(".likeContainer").toggleClass("heart_filled");
  }


  videoDetail(id: any) {
    this.api.clipDetailUser(id).subscribe((res: any) => {
      this.clipUrl = this.api.clipsImageUrl
      this.videoData = this.api.clipsImageUrl + res.data.trailer

      this.clipDetail = res.data
      this.tags = res.data.tags
      this.relatedClips = res.data.relatedClips
      setTimeout(() => {
        $('.commonVideo_slider').slick({
          slidesToShow: 3,
          slidesToScroll: 1,
          autoplay: false,
          autoplaySpeed: 3000,
          dots: true,
          infinite: false,
          // centerMode: true,
          // variableWidth: true,
          prevArrow: $('.prev'),
          nextArrow: $('.next'),
          responsive: [
            {
              breakpoint: 1400,
              settings: {
                slidesToShow: 3,

              }
            },
            {
              breakpoint: 992,
              settings: {
                slidesToShow: 2,
              }
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 1,
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
          ]
        });
      }, 1000);

    })
  }

  buyVideo() {
    let Subscription:any = sessionStorage.getItem('isSubscription')
    let data = JSON.parse(sessionStorage.getItem('studioDetail') || '')
    if (Subscription === '1' || data.isSubscription === 1) {
      let fd = new FormData
      fd.append('CID', this.clipDetail.id)
      fd.append('SID', this.clipDetail.SID)
      fd.append('title', this.clipDetail.title)
      fd.append('image', this.clipDetail.image)
      fd.append('trailer', this.clipDetail.trailer)
      fd.append('video', this.clipDetail.video)
      fd.append('total', this.clipDetail.price)
      fd.append('paymentDate', moment(this.time).format('YYYY-MM-DD'))
      this.api.buynow(fd).subscribe((res: any) => {
        this.message.sucessMessage(res.message, 2000)
        if(res.status==200){
        this.router.navigate(['/purchased-video'])
        }
      })
    }
    else {
      this.router.navigate(['/membership'])
    }
  }




  addToCart() {
    let fd = new FormData
    fd.append('CID', this.clipDetail.id)
    fd.append('SID', this.clipDetail.SID)
    fd.append('title', this.clipDetail.title)
    fd.append('image', this.clipDetail.image)
    fd.append('trailer', this.clipDetail.trailer)
    fd.append('video', this.clipDetail.video)
    fd.append('total', this.clipDetail.price)
    this.api.addToCart(fd).subscribe((res: any) => {
      this.message.sucessMessage(res.message, 1000)
      this.videoDetail(this.id)

    })
  }

  goToCart() {
    this.router.navigate(['/cart'])
  }
}
