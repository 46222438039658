import { LocationStrategy, HashLocationStrategy, DatePipe } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatMenuModule } from '@angular/material/menu';
import { MatRadioModule } from '@angular/material/radio';
import { MatStepperModule } from '@angular/material/stepper';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxOtpInputModule } from 'ngx-otp-input';
import { NgxVideoListPlayerModule } from 'ngx-video-list-player';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { AppComponent } from 'src/app/app.component';
import { CoreModule } from 'src/app/common/interceptor/core.module';
import { AboutUsComponent } from './components/about-us/about-us.component';
import { AddTokenComponent } from './components/add-token/add-token.component';
import { AllCategoriesComponent } from './components/all-categories/all-categories.component';
import { CartComponent } from './components/cart/cart.component';
import { CategoryListComponent } from './components/category-list/category-list.component';
import { ChangePasswordComponent } from './components/change-password/change-password.component';
import { ContactUsComponent } from './components/contact-us/contact-us.component';
import { DisclaimerComponent } from './components/disclaimer/disclaimer.component';
import { EditCardComponent } from './components/edit-card/edit-card.component';
import { EditEmailComponent } from './components/edit-email/edit-email.component';
import { EditNameComponent } from './components/edit-name/edit-name.component';
import { EditPaymentComponent } from './components/edit-payment/edit-payment.component';
import { FooterComponent } from './components/footer/footer.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { HeaderComponent } from './components/header/header.component';
import { HomeComponent } from './components/home/home.component';
import { LoginComponent } from './components/login/login.component';
import { ArtistDetailsComponent } from './components/modal/artist-details/artist-details.component';
import { DisclaimerPolicyComponent } from './components/modal/disclaimer-policy/disclaimer-policy.component';
import { LandingPageVideoComponent } from './components/modal/landing-page-video/landing-page-video.component';
import { ModelDetailsComponent } from './components/model-details/model-details.component';
import { ModelsComponent } from './components/models/models.component';
import { MyProfileComponent } from './components/my-profile/my-profile.component';
import { NotificationComponent } from './components/notification/notification.component';
import { PurchasedVideoComponent } from './components/purchased-video/purchased-video.component';
import { SeeAllModelsComponent } from './components/see-all-models/see-all-models.component';
import { SeeAllVideosComponent } from './components/see-all-videos/see-all-videos.component';
import { SignupComponent } from './components/signup/signup.component';
import { StudioDetailsComponent } from './components/studio-details/studio-details.component';
import { StudioSignupComponent } from './components/studio-signup/studio-signup.component';
import { StudiosComponent } from './components/studios/studios.component';
import { VerifyEmailComponent } from './components/verify-email/verify-email.component';
import { VideoDetailComponent } from './components/video-detail/video-detail.component';
import { MemberRoutingModule } from './member-routing.module';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { VerifyOtpComponent } from './components/verify-otp/verify-otp.component';
import { MembershipComponent } from './membership/membership.component';
import { EditdobComponent } from './editdob/editdob.component';
import { PrivacyPolicyComponent } from './components/privacy-policy/privacy-policy.component';
import { TermsConditionsComponent } from './components/terms-conditions/terms-conditions.component';
import { AddCardComponent } from './components/add-card/add-card.component';
import { TokenListComponent } from './components/token-list/token-list.component';
import { BonusTokenComponent } from './components/bonus-token/bonus-token.component';
import { FavoritesComponent } from './components/favorites/favorites.component';
import { TokenAddedComponent } from './components/token-added/token-added.component';
import { CardforsubscriptionComponent } from './components/cardforsubscription/cardforsubscription.component';
import { PurchasedVideoDetailComponent } from './components/purchased-video-detail/purchased-video-detail.component';
import { MatTabsModule } from '@angular/material/tabs';
import { PurchasedTokenHistoryComponent } from './components/purchased-token-history/purchased-token-history.component';
@NgModule({
  declarations: [
    LandingPageVideoComponent,
    ArtistDetailsComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    LoginComponent,
    SignupComponent,
    ForgotPasswordComponent,
    AboutUsComponent,
    ContactUsComponent,
    VideoDetailComponent,
    ModelDetailsComponent,
    CategoryListComponent,
    PurchasedVideoComponent,
    ModelsComponent,
    MyProfileComponent,
    EditNameComponent,
    EditEmailComponent,
    VerifyEmailComponent,
    ChangePasswordComponent,
    EditPaymentComponent,
    EditCardComponent,
    StudiosComponent,
    CartComponent,
    AddTokenComponent,
    AllCategoriesComponent,
    DisclaimerComponent,
    DisclaimerPolicyComponent,
    StudioSignupComponent,
    NotificationComponent,
    StudioDetailsComponent,
    SeeAllModelsComponent,
    SeeAllVideosComponent,
    ResetPasswordComponent,
    VerifyOtpComponent,
    MembershipComponent,
    EditdobComponent,
    PrivacyPolicyComponent,
    TermsConditionsComponent,
    AddCardComponent,
    TokenListComponent,
    BonusTokenComponent,
    FavoritesComponent,
    TokenAddedComponent,
    CardforsubscriptionComponent,
    PurchasedVideoDetailComponent,
    PurchasedTokenHistoryComponent,

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatDialogModule,
    MatMenuModule,
    MatCheckboxModule,
    NgxOtpInputModule,
    MatRadioModule,
    HttpClientModule,
    NgxVideoListPlayerModule,
    MatStepperModule,
    FormsModule,
    CoreModule,
    ReactiveFormsModule,
    MemberRoutingModule,
    MatTabsModule
  ],
  providers: [{ provide: LocationStrategy, useClass: HashLocationStrategy},DatePipe],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class MemberModule { }
