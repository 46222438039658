<!-- <app-header></app-header> -->

<section class="breadcrumb-banner">
    <div class="container">
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item ps-0 active" [routerLink]="['/home']">Home</li>
                <li class="breadcrumb-item active" [routerLink]="['/my-profile']">My Profile</li>
                <li class="breadcrumb-item"> Token Added</li>
            </ol>
        </nav>
    </div>
</section>

<section class="add-token-sec  sec_padding_bottom">
    <div class="container justify-content-center d-flex">
        <div class="main-box w-75 justify-content-center d-flex" >
          
                <!-- <div class="col-md-6">
                    <div class="img-wrap d-flex align-items-center justify-content-center">
                        <img src="assets/images/add-token-img.svg">
                    </div>
                </div> -->
                <div class=" text-center">




                    <div class="img-wrap me-4">
                        <img class="img-fluid" src="assets/images/coins.svg">
                    </div>

<div>  

  <h4  class="ff_kalam fw-regular mt-2"> Token Added</h4>


                    <p> you have added {{token}} token in your wallet</p>
                </div>
                <button    class="btn w-100 purple_btn btn70 fw-bold mt-4" (click)="cart()">Continue to cart Payment</button>

                 <a class="text-white mt-5 d-block cursor_p" > Check Your Wallet</a>



                </div>


            
    </div>

          
       
    </div>
</section>

<!-- <app-footer></app-footer> -->