<app-header></app-header>

<section class="clips-list">
    <div class="container">
        <div class="cmn-box my-4 p-4">
            <div class="d-flex align-items-center justify-content-between mb-4">
                <h3 class="text-white fw-medium mb-0">Clips</h3>
                <button class="btn red_btn fw-bold shadow-none" [routerLink]="['/studio-add-clip']">Add Clip</button>
            </div>
            <div class="">
                <table id="clipsTable" class="table cmn-table">
                    <thead>
                        <tr>
                            <th scope="col">Video Clips</th>
                            <th scope="col" class="description">Video Description</th>
                            <th scope="col">Amount</th>
                            <th scope="col">Category</th>
                            <th scope="col" class="text-end">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let clip of clipdata">
                            <td>
                                <span class="d-flex align-items-center videos-sec">
                                    <span class="img-wrapper position-relative">
                                        <!-- <img class="fit-img" src="assets/images/studiomodule/clip1.png"> -->
                                        <video class="img-fluid" [src]="baseUrl+imageUrl+clip.trailer" height="200"
                                            controls controlsList="nodownload"></video>
                                        <!-- <span class="video-overlay">  </span> -->

                                        <!-- <span class="video-overlay"></span> -->
                                        <!-- <span class="d-flex align-items-center duration">
                                            <svg class="me-1" width="5" height="5" viewBox="0 0 5 5" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path d="M0.416626 0V5L4.58329 2.5L0.416626 0Z" fill="#F8F8F8" />
                                            </svg>
                                           {{clip.duration}} 
                                        </span> -->
                                    </span>
                                    <span class="ps-3 text fw-medium">{{clip.title}}</span>
                                </span>
                            </td>
                            <td class="description">
                                <div class="line-clamp3">
                                    {{clip.description}}</div>
                            </td>
                            <td>${{clip.price}}</td>
                            <td>{{clip.name}}</td>
                            <td class="text-end">
                                <div class="d-flex align-items-center">  
                                <span class="color_pink " role="button" (click)="viewDetail(clip.id)">View
                                    Details</span>
                                <div class="btn-group dropstart">
                                    <button type="button" class="btn shadow-none" data-bs-toggle="dropdown"
                                        aria-expanded="false">
                                        <img src="assets/images/studiomodule/moreicon.svg">
                                    </button>
                                    <ul class="dropdown-menu">
                                        <li><a class="dropdown-item fs14 text-white" role="button"
                                                (click)="editClip(clip.id)"><img class="me-3"
                                                    src="assets/images/studiomodule/edit.svg"> Edit</a></li>
                                        <li><a class="dropdown-item fs14 text-white" (click)="deleteClip(clip.id)"><img
                                                    class="me-3" src="assets/images/studiomodule//delete.svg">
                                                Delete</a></li>
                                    </ul>
                                </div>

                            </div>
                            </td>
                        </tr>
                        <!-- 
                      <tr>
                        <td>
                            <span class="d-flex align-items-center videos-sec">
                                <span class="img-wrapper position-relative">
                                    <img class="fit-img" src="assets/images/studiomodule/clip2.png">
                                    <span class="video-overlay"></span>
                                    <span class="d-flex align-items-center duration">
                                        <svg class="me-1" width="5" height="5" viewBox="0 0 5 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M0.416626 0V5L4.58329 2.5L0.416626 0Z" fill="#F8F8F8"/>
                                        </svg>
                                        1:12:53
                                    </span>
                                </span>
                                <span class="ps-3 text fw-medium">Alura Jenson in Love with Halle...</span>
                            </span>
                        </td>
                        <td class="description">
                            <div class="line-clamp3">Phasellus pulvinar sem nec dui dictum sodales. Proin sed porta mauris. Aenean quis auctor arcu, non tincidunt ligul...</div></td>
                        <td>$15.25</td>
                        <td>Corporal Punishment</td>
                        <td class="text-end">
                            <span class="color_pink me-3" role="button"   [routerLink]="['/studio-clip-details']">View Details</span>
                            <div class="btn-group dropstart">
                                <button type="button" class="btn shadow-none" data-bs-toggle="dropdown" aria-expanded="false">
                                    <img src="assets/images/studiomodule/moreicon.svg">
                                </button>
                                <ul class="dropdown-menu">
                                    <li><a class="dropdown-item fs14 text-white" role="button" [routerLink]="['/studio-edit-clips']"><img class="me-3" src="assets/images/studiomodule/edit.svg"> Edit</a></li>
                                    <li><a class="dropdown-item fs14 text-white"  (click)="deleteClip()"><img class="me-3" src="assets/images/studiomodule/delete.svg"> Delete</a></li>
                                </ul>
                            </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                            <span class="d-flex align-items-center videos-sec">
                                <span class="img-wrapper position-relative">
                                    <img class="fit-img" src="assets/images/studiomodule/clip3.png">
                                    <span class="video-overlay"></span>
                                    <span class="d-flex align-items-center duration">
                                        <svg class="me-1" width="5" height="5" viewBox="0 0 5 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M0.416626 0V5L4.58329 2.5L0.416626 0Z" fill="#F8F8F8"/>
                                        </svg>
                                        1:12:53
                                    </span>
                                </span>
                                <span class="ps-3 text fw-medium">Fresh Hot Meat: Jessie Saint</span>
                            </span>
                        </td>
                        <td class="description">
                            <div class="line-clamp3">Donec aliquam aliquet accumsan. Duis dictum ex quis orci lacinia posuere. Ut vehicula, ligula quis pulvinar porta, ero...</div></td>
                        <td>$15.25</td>
                        <td>Domination</td>
                        <td class="text-end">
                            <span class="color_pink me-3" role="button" [routerLink]="['/studio-clip-details']">View Details</span>
                            <div class="btn-group dropstart">
                                <button type="button" class="btn shadow-none" data-bs-toggle="dropdown" aria-expanded="false">
                                    <img src="assets/images/studiomodule/moreicon.svg">
                                </button>
                                <ul class="dropdown-menu">
                                    <li><a class="dropdown-item fs14 text-white" role="button" [routerLink]="['/studio-edit-clips']"><img class="me-3" src="assets/images/studiomodule/edit.svg"> Edit</a></li>
                                    <li><a class="dropdown-item fs14 text-white"  (click)="deleteClip()"><img class="me-3" src="assets/images/studiomodule/delete.svg"> Delete</a></li>
                                </ul>
                            </div>
                        </td>
                      </tr>

                      <tr>
                        <td>
                            <span class="d-flex align-items-center videos-sec">
                                <span class="img-wrapper position-relative">
                                    <img class="fit-img" src="assets/images/studiomodule/clip4.png">
                                    <span class="video-overlay"></span>
                                    <span class="d-flex align-items-center duration">
                                        <svg class="me-1" width="5" height="5" viewBox="0 0 5 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M0.416626 0V5L4.58329 2.5L0.416626 0Z" fill="#F8F8F8"/>
                                        </svg>
                                        1:12:53
                                    </span>
                                </span>
                                <span class="ps-3 text fw-medium">Fishes In The Sea</span>
                            </span>
                        </td>
                        <td class="description">
                            <div class="line-clamp3">Pellentesque ut felis rutrum, pellentesque nunc feugiat, aliquet risus. Donec orci tortor, pulvinar vel convallis vel...</div></td>
                        <td>$15.25</td>
                        <td>Humiliation</td>
                        <td class="text-end">
                            <span class="color_pink me-3" role="button" [routerLink]="['/studio-clip-details']">View Details</span>
                            <div class="btn-group dropstart">
                                <button type="button" class="btn shadow-none" data-bs-toggle="dropdown" aria-expanded="false">
                                    <img src="assets/images/studiomodule/moreicon.svg">
                                </button>
                                <ul class="dropdown-menu">
                                    <li><a class="dropdown-item fs14 text-white" role="button" [routerLink]="['/studio-edit-clips']"><img class="me-3" src="assets/images/studiomodule/edit.svg"> Edit</a></li>
                                    <li><a class="dropdown-item fs14 text-white"  (click)="deleteClip()"><img class="me-3" src="assets/images/studiomodule/delete.svg"> Delete</a></li>
                                </ul>
                            </div>
                        </td>
                      </tr>

                      <tr>
                        <td>
                            <span class="d-flex align-items-center videos-sec">
                                <span class="img-wrapper position-relative">
                                    <img class="fit-img" src="assets/images/studiomodule/clip5.png">
                                    <span class="video-overlay"></span>
                                    <span class="d-flex align-items-center duration">
                                        <svg class="me-1" width="5" height="5" viewBox="0 0 5 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M0.416626 0V5L4.58329 2.5L0.416626 0Z" fill="#F8F8F8"/>
                                        </svg>
                                        1:12:53
                                    </span>
                                </span>
                                <span class="ps-3 text fw-medium">Fishes In The Sea</span>
                            </span>
                        </td>
                        <td class="description">
                            <div class="line-clamp3">is my lucky fucking day! Halle Hayes is in the house! Just look at her there on her knees in the back of a car</div></td>
                        <td>$15.25</td>
                        <td>Lesbian</td>
                        <td class="text-end">
                            <span class="color_pink me-3" role="button" [routerLink]="['/studio-clip-details'] ">View Details</span>
                            <div class="btn-group dropstart">
                                <button type="button" class="btn shadow-none" data-bs-toggle="dropdown" aria-expanded="false">
                                    <img src="assets/images/studiomodule/moreicon.svg">
                                </button>
                                <ul class="dropdown-menu">
                                    <li><a class="dropdown-item fs14 text-white" role="button" [routerLink]="['/studio-edit-clips']"><img class="me-3" src="assets/images/studiomodule/edit.svg"> Edit</a></li>
                                    <li><a class="dropdown-item fs14 text-white"  (click)="deleteClip()"><img class="me-3" src="assets/images/studiomodule/delete.svg"> Delete</a></li>
                                </ul>
                            </div>
                        </td>
                      </tr> -->



                    </tbody>
                </table>
            </div>
        </div>
    </div>
</section>


<!-- Modal -->
<div class="modal fade custom_model" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <button type="button" class="btn-close shadow-none" data-bs-dismiss="modal" aria-label="Close"></button>
            <div class="modal-body text-center pt-5">
                <img src="assets/images/studiomodule/delete_clip.svg" alt="" class="img-fluid mb-3">
                <h3>Are you sure!</h3>
                <p class="fw-light">You want to delete this clip</p>
            </div>
            <div class="modal-footer gap-3">
                <button type="button" class="btn dark_btn btn60" data-bs-dismiss="modal">Cancel</button>
                <button type="button" class="btn red_btn shadow-none btn60">Delete</button>
            </div>
        </div>
    </div>
</div>

<app-studio-footer> </app-studio-footer>