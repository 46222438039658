<!-- <app-header></app-header> -->

<!-- <section class="breadcrumb-banner">
  <div class="container">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item ps-0 active" [routerLink]="['/home']">Home</li>
        <li class="breadcrumb-item">About Us</li>
      </ol>
    </nav>
  </div>
</section> -->

<section class="about-us">
  <div class="container">
    <div class="row">
      <div class="col-md-6">
        <div class="img-wrap pe-0 pe-md-3">
          <img class="w-100 img-fluid" src="assets/images/about-us-img.png">
        </div>
      </div>
      <div class="col-md-6">
        <div class="text-wrap ps-0 ps-md-3 d-flex align-items-center">
          <div class="inner-sec">
            <h2 class="mb-4 pb-1 text-uppercase fw-bold">About US</h2>
            <p class="color_grey mb-4 pb-1" [innerHTML]="aboutData?.content"> </p>

            <!-- <p class="color_grey mb-4 pb-1">Founded in 2008 by two fellow hobbyists with an enthusiasm for film, and an appetite for the taboo, Chokechamber was developed and has produced over 1000 short films in the death horror genre to date. Michelle Lee, with her disturbing, yet sinfully erotic stories of terror, horror, and most things macabre, combined with her business counterpart's innate fetish for asphyxia, brought to life the video company you all know and enjoy today.</p> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<!-- Our Models Section Start -->
<!-- <section class="ourModels sec_padding px-5">
  <div class="container-fluid">
    <div class="center_heading text-center mb-5">
      <h3 class="ff_kalam fw-regular display-4">Our Models</h3>
      <img src="assets/images/border_shade.png" alt="">
    </div>
    <div class="modelSliderContainer position-relative" >
      <div class="models_slider1">
        <div class="modelContainer position-relative cursor_pointer" (click)="artist_detail(model)" *ngFor="let model of modelData">
          <div class="model_img">
            <img  *ngIf="model.profile"class="img-fluid fit-img" [src]="imageUrl + model.profile">
            <div class="overlay"></div>
          </div>

          <div class="item_content">
            <h4 class="fw-light mb-4">{{model.name}}
            </h4>

            <div class="info">
              <p class="mb-1 fw-bold">Gender</p>
              <h4 class="fw-light m-0">{{model.gender}}</h4>
            </div>

            <div class="detailEye position-absolute">
              <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50" fill="none">
                <circle cx="25" cy="25" r="25" fill="black" />
                <path
                  d="M25 19.5C26.8387 19.4939 28.6419 20.0068 30.2021 20.9797C31.7624 21.9527 33.0164 23.3462 33.82 25C32.17 28.37 28.8 30.5 25 30.5C21.2 30.5 17.83 28.37 16.18 25C16.9836 23.3462 18.2376 21.9527 19.7979 20.9797C21.3581 20.0068 23.1613 19.4939 25 19.5ZM25 17.5C20 17.5 15.73 20.61 14 25C15.73 29.39 20 32.5 25 32.5C30 32.5 34.27 29.39 36 25C34.27 20.61 30 17.5 25 17.5ZM25 22.5C25.663 22.5 26.2989 22.7634 26.7678 23.2322C27.2366 23.7011 27.5 24.337 27.5 25C27.5 25.663 27.2366 26.2989 26.7678 26.7678C26.2989 27.2366 25.663 27.5 25 27.5C24.337 27.5 23.7011 27.2366 23.2322 26.7678C22.7634 26.2989 22.5 25.663 22.5 25C22.5 24.337 22.7634 23.7011 23.2322 23.2322C23.7011 22.7634 24.337 22.5 25 22.5ZM25 20.5C22.52 20.5 20.5 22.52 20.5 25C20.5 27.48 22.52 29.5 25 29.5C27.48 29.5 29.5 27.48 29.5 25C29.5 22.52 27.48 20.5 25 20.5Z"
                  fill="black" />
                <path
                  d="M35.5795 23.8137C36.1212 24.5223 36.1212 25.4789 35.5795 26.1863C33.8732 28.4137 29.7795 33 25 33C20.2206 33 16.1269 28.4137 14.4206 26.1863C14.157 25.847 14.014 25.4296 14.014 25C14.014 24.5704 14.157 24.153 14.4206 23.8137C16.1269 21.5863 20.2206 17 25 17C29.7795 17 33.8732 21.5863 35.5795 23.8137V23.8137Z"
                  stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <path
                  d="M25 28.4287C26.8936 28.4287 28.4286 26.8937 28.4286 25.0001C28.4286 23.1066 26.8936 21.5715 25 21.5715C23.1065 21.5715 21.5715 23.1066 21.5715 25.0001C21.5715 26.8937 23.1065 28.4287 25 28.4287Z"
                  stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
            </div>

          </div>
        </div>

      </div>
      <div class="slide_arrow position-absolute w-100">
        <ul class="list-unstyled">
          <li class="prevText"></li>
          <li class="nextText"></li>
        </ul>
      </div>
    </div>
  </div>
</section> -->

<!-- <app-footer></app-footer> -->