import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-token-added',
  templateUrl: './token-added.component.html',
  styleUrls: ['./token-added.component.scss'],
})
export class TokenAddedComponent implements OnInit {
  token: any
  customValue: any;
  constructor(private router:Router,private route:ActivatedRoute) { 
    const navigationState = window.history.state;
    this.customValue = navigationState?.state?.customKey;
    console.log(this.customValue); 
    // this.route.data.subscribe(data => {
    //   this.customValue = data['state']['customKey'];
    //   console.log(this.customValue); 
    //   // localStorage.setItem('studioToken', this.customValue)
    // });
  }
  ngOnInit(): void {
    this.token=sessionStorage.getItem('amount')
      window.history.pushState(null, '', window.location.href);
    window.onpopstate = function () {
      window.history.pushState(null, '', window.location.href);
    };
  
    
  }

  cart() {
    let a:any=localStorage.getItem('studioToken')
    console.log(a);
    
    const url='https://chokechamber.com/#/cart'
    const dataToPass = { customKey: a };
    const queryParams = new URLSearchParams(dataToPass).toString();
    console.log(`${url}?${queryParams}`);
   window.location.href = `${url}?${queryParams}`;
    // window.location.href = 'https://chokechamber.com/#/cart';
  }
    // http://chokechamberangular.harishparas.com/#/cart
    // this.router.navigate(['/cart'])
  

}