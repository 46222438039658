import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { ApiService } from 'src/app/common/api-service/api.service';
import { MessagehandlingService } from 'src/app/common/message/messagehandling.service';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss']
})
export class CartComponent implements OnInit {
  cartData: any;
  token: any;
  baseUrl = this.api.clipsImageUrl
  cartPrice: any = []
  totalPrice: number = 0;
  OID: any;
  total: any;
  time = new Date();
  adminToken: any;
  bonusToken: any;
  commonToken: any;

  constructor(private api: ApiService, private message: MessagehandlingService, private router: Router) { }

  ngOnInit(): void {
    // window.location.href = 'http://chokechamberangular.harishparas.com/#/cart';
    this.cartList()
    this.tokenDetail()

  }

  // cartList() {
  //   this.cartPrice = []
  //   this.totalPrice = 0
  //   this.api.cartList().subscribe((res: any) => {
  //     this.cartData = res.data
  //     this.OID = res.OID
  //     this.token = res.token
  //     this.cartData?.forEach((element: any) => {
  //       this.cartPrice.push({ price: element.total })
  //       this.calculateTotal()
  //     });
  //   })

  // }


  // calculateTotal() {

  //   this.cartPrice.map((cart: any) => {
  //     this.totalPrice += cart.price;
  //     this.total = this.totalPrice
  //   });


  // }

  cartList() {
    this.cartPrice = [];
    this.totalPrice = 0;
    this.api.cartList().subscribe((res: any) => {
      this.cartData = res.data;
      this.OID = res.OID;
      this.token = res.token;
      this.adminToken = res.adminToken
      this.bonusToken = res.bonusToken
      this.commonToken = res.commonToken

      this.cartData?.forEach((element: any) => {
        this.cartPrice.push({ price: element.total });
      });
      this.calculateTotal();
    });
  }

  calculateTotal() {
    this.totalPrice = this.cartPrice.reduce((total: any, cartItem: any) => total + cartItem.price, 0);
    this.total = this.totalPrice;
  }


  tokenDetail() {
    this.api.tokenDetail().subscribe((res: any) => {


    })
  }

  deleteItem(id: any) {

    this.api.deleteItem(id).subscribe((res: any) => {
      this.message.sucessMessage(res.message, 1000)
      this.cartList()

    })
  }

  // [routerLink]="['/video-detail']"

  videoDetail(id: any) {
    this.router.navigate([`/video-detail/${id}`])

  }

  checkout() {
    let Subscription: any = sessionStorage.getItem('isSubscription')
    let data = JSON.parse(sessionStorage.getItem('studioDetail') || '')
    if (Subscription === '1' || data.isSubscription === '1') {
      let fd = new FormData
      fd.append('OID', this.OID)
      fd.append('total', this.total)
      fd.append('paymentDate', moment(this.time).format('YYYY-MM-DD'))
      this.api.checkout(fd).subscribe((res: any) => {
        this.message.sucessMessage(res.message, 1000)
        if(res.status==200){
          this.router.navigate(['/purchased-video'])
          }
        this.cartList()
      })
    }
    else {
      this.router.navigate(['/membership'])
    }
  }

}
