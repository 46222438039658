import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import * as moment from 'moment';
import { ApiService } from 'src/app/common/api-service/api.service';
import { MessagehandlingService } from 'src/app/common/message/messagehandling.service';
@Component({
  selector: 'app-add-token',
  templateUrl: './add-token.component.html',
  styleUrls: ['./add-token.component.scss'],
  providers: [Location, { provide: LocationStrategy, useClass: PathLocationStrategy }],
})
export class AddTokenComponent implements OnInit {
  tokenForm: FormGroup
  amount: any
  cardDetail: any;
  Id: any;
  time = new Date()
  cardData: any;
  cvvForm: FormGroup
  newToken: any;
  constructor(private fb: FormBuilder, private api: ApiService, public message: MessagehandlingService, private router: Router,private route: ActivatedRoute) {
  
    const key = this.route.snapshot.queryParamMap.get('key');
    if (key) {
      localStorage.setItem('studioToken',key)
       console.log('Key:', key);
       this.newToken=key
    } else {
      console.log('Key not found in the URL');
    }
    this.tokenForm = this.fb.group({
      amount: '',
      // isCardSelected: '',

    })

    this.cvvForm = this.fb.group({
      cvv: ''
    })
  }

  ngOnInit(): void {
    window.history.pushState(null, '', window.location.href);
    window.onpopstate = function () {
      window.history.pushState(null, '', window.location.href);
    };

    this.cardList()
  }


  cardList() {
    this.api.cardList().subscribe((res: any) => {
      this.cardDetail = res.data

    })
  }

  cardDelete(id: any) {
    this.Id = id

  }



  deleteCard() {
    this.api.deleteCard(this.Id).subscribe((res: any) => {
      this.message.sucessMessage(res.message, 2000)
      this.cardList()
    })
  }
 
  selectedCard(data: any) {

    this.cardData = data

  }

  // payNow(amount: any) {
  //   sessionStorage.setItem('amount',this.amount)
  //   if (this.cvvForm.value.cvv == '') {
  //     this.message.errorMessage('please enter cvv', 1000)
  //   }
  //   if( this.cardDetail.length==0){
  //     this.message.errorMessage('please add card',1000)
  //   }

  //   if (amount == '' || amount == undefined) {
  //     this.message.errorMessage('Please enter amount', 1000)
  //   }
  //   else {
  //     let fd = new FormData
  //     fd.append('cardNumber', this.cardData?.cardNumber)
  //     fd.append('expDate', this.cardData?.expDate)
  //     fd.append('cvv', this.cvvForm.value?.cvv)
  //     fd.append('amount', this.amount)
  //     fd.append('cardHolderName', this.cardData?.cardHolderName)
  //     // fd.append('isSave', this.tokenForm.value.isSave)
  //     fd.append('paymentDate', moment(this.time).format('YYYY-MM-DD'))
  //     fd.append('paymentTime', moment(this.time).format('hh:mm a'))
  //     this.api.addToken(fd).subscribe((res: any) => {
  //       if(res.status==200){
  //         this.message.sucessMessage(res.message,1000)
  //       this.router.navigate(['/token-added'])
  //       }
  //       else{
  //         this.message.errorMessage(res.message,1000)
  //       }
  //     })
  //   }
  // }

  payNow(amount: any) {
    sessionStorage.setItem('amount', this.amount);
    if (this.cvvForm.value.cvv == '') {
      this.message.errorMessage('please enter cvv', 1000);
    }
    if (this.cardDetail.length == 0) {
      this.message.errorMessage('please add card', 1000);
    }

    if (amount == '' || amount == undefined) {
      this.message.errorMessage('Please enter amount', 1000);
    } else {
      let fd = new FormData();
      fd.append('cardNumber', this.cardData?.cardNumber);
      fd.append('expDate', this.cardData?.expDate);
      fd.append('cvv', this.cvvForm.value?.cvv);
      fd.append('amount', this.amount);
      fd.append('cardHolderName', this.cardData?.cardHolderName);
      fd.append('paymentDate', moment(this.time).format('YYYY-MM-DD'));
      fd.append('paymentTime', moment(this.time).format('hh:mm a'));

      const navigationExtras: NavigationExtras = {
        state: {
          // You can add any data you want to send here
          customKey: this.newToken
        }
      };

      this.api.addToken(fd).subscribe((res: any) => {
        if (res.status == 200) {
          this.message.sucessMessage(res.message, 1000);
          this.router.navigate(['/token-added'], navigationExtras);
        } else {
          this.message.errorMessage(res.message, 1000);
        }
      });
    }
}

  numberOnly(event: any): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }

  checkEvent(event: any) {
    if (event.target.value == '' && event.which == 32) {
      event.preventDefault();
    }
  }

}
