import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AboutUsComponent } from './components/about-us/about-us.component';
import { AddTokenComponent } from './components/add-token/add-token.component';
import { AllCategoriesComponent } from './components/all-categories/all-categories.component';
import { CartComponent } from './components/cart/cart.component';
import { CategoryListComponent } from './components/category-list/category-list.component';
import { ChangePasswordComponent } from './components/change-password/change-password.component';
import { ContactUsComponent } from './components/contact-us/contact-us.component';
import { EditCardComponent } from './components/edit-card/edit-card.component';
import { EditEmailComponent } from './components/edit-email/edit-email.component';
import { EditNameComponent } from './components/edit-name/edit-name.component';
import { EditPaymentComponent } from './components/edit-payment/edit-payment.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { HomeComponent } from './components/home/home.component';
import { LoginComponent } from './components/login/login.component';
import { ModelDetailsComponent } from './components/model-details/model-details.component';
import { ModelsComponent } from './components/models/models.component';
import { MyProfileComponent } from './components/my-profile/my-profile.component';
import { NotificationComponent } from './components/notification/notification.component';
import { PurchasedVideoComponent } from './components/purchased-video/purchased-video.component';
import { SeeAllModelsComponent } from './components/see-all-models/see-all-models.component';
import { SeeAllVideosComponent } from './components/see-all-videos/see-all-videos.component';
import { SignupComponent } from './components/signup/signup.component';
import { StudioDetailsComponent } from './components/studio-details/studio-details.component';
import { StudioSignupComponent } from './components/studio-signup/studio-signup.component';
import { StudiosComponent } from './components/studios/studios.component';
import { VerifyEmailComponent } from './components/verify-email/verify-email.component';
import { VerifyOtpComponent } from './components/verify-otp/verify-otp.component';
import { VideoDetailComponent } from './components/video-detail/video-detail.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { MembershipComponent } from './membership/membership.component';
import { EditdobComponent } from './editdob/editdob.component';
import { TermsConditionsComponent } from './components/terms-conditions/terms-conditions.component';
import { PrivacyPolicyComponent } from './components/privacy-policy/privacy-policy.component';
import { AddCardComponent } from './components/add-card/add-card.component';
import { BonusTokenComponent } from './components/bonus-token/bonus-token.component';
import { TokenListComponent } from './components/token-list/token-list.component';
import { FavoritesComponent } from './components/favorites/favorites.component';
import { TokenAddedComponent } from './components/token-added/token-added.component';
import { CardforsubscriptionComponent } from './components/cardforsubscription/cardforsubscription.component';
import { PurchasedVideoDetailComponent } from './components/purchased-video-detail/purchased-video-detail.component';
import { PurchasedTokenHistoryComponent } from './components/purchased-token-history/purchased-token-history.component';



const routes: Routes = [

  // member components

  { path: 'home', component: HomeComponent },
  { path: 'login', component: LoginComponent },
  { path: 'signup', component: SignupComponent },
  { path: 'forgot-password', component: ForgotPasswordComponent },
  { path: 'terms-condition', component: TermsConditionsComponent },
  { path: 'privacy-policy', component: PrivacyPolicyComponent },
  { path: 'about-us', component: AboutUsComponent },
  { path: 'contact-us', component: ContactUsComponent },
  { path: 'video-detail/:id', component: VideoDetailComponent },
  { path: 'model-details/:id', component: ModelDetailsComponent },
  { path: 'category-list/:id', component: CategoryListComponent },
  { path: 'purchased-video', component: PurchasedVideoComponent },
  { path: 'purchased-token-history', component: PurchasedTokenHistoryComponent },
  { path: 'purchased-video-detail/:id', component: PurchasedVideoDetailComponent },
  { path: 'models', component: ModelsComponent },
  { path: 'my-profile', component: MyProfileComponent },
  { path: 'edit-name', component: EditNameComponent },
  { path: 'edit-email', component: EditEmailComponent },
  { path: 'verify-email', component: VerifyEmailComponent },
  { path: 'change-password', component: ChangePasswordComponent },
  { path: 'edit-payment-method', component: EditPaymentComponent },
  { path: 'edit-card', component: EditCardComponent },
  { path: 'studios', component: StudiosComponent },
  { path: 'cart', component: CartComponent },
  { path: 'add-token', component: AddTokenComponent },
  { path: 'add-card', component: AddCardComponent },
  { path: 'bonus-token', component: BonusTokenComponent },
  { path: 'token-list', component: TokenListComponent },
  { path: 'all-categories', component: AllCategoriesComponent },
  { path: 'studio-signup', component: StudioSignupComponent },
  { path: 'notification', component: NotificationComponent },
  { path: 'studio-details/:id', component: StudioDetailsComponent },
  { path: 'see-all-models/:id', component: SeeAllModelsComponent },
  { path: 'see-all-videos/:id', component: SeeAllVideosComponent },
  { path: 'reset-password', component: ResetPasswordComponent },
  { path: 'verify-otp', component: VerifyOtpComponent },
  { path: 'membership', component: MembershipComponent },
  { path: 'editdob', component: EditdobComponent },
  { path: 'favorites', component: FavoritesComponent },
  { path: 'token-added', component: TokenAddedComponent },
  { path: 'card-detail', component: CardforsubscriptionComponent }



  // end

  // studio components

];

@NgModule({
  
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class MemberRoutingModule { }
