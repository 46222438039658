<header class="ff_smooch navbarPadding">
  <nav class="navbar navbar-expand-lg navbar-dark  bg-black">
    <div class="container-fluid">
      <a class="navbar-brand">
        <img src="assets/images/logo.png" alt="">
      </a>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav mx-auto mb-2 mb-lg-0">
          <li class="nav-item">
            <a class="nav-link" [routerLink]="['/home']" [routerLinkActive]="['active']">Home</a>
          </li>
          <li class="nav-item">
            <a class="nav-link">
              <button class="bg-transparent btn-dark border-0 p-0" mat-icon-button [matMenuTriggerFor]="catemenu"
                aria-label="Example icon-button with a menu">
                <span>Categories</span>
                <i class="icon ms-2">
                  <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
                    <path d="M1 4L5 8L9 4" stroke="#F8F8F8" stroke-width="1.5" stroke-linecap="round"
                      stroke-linejoin="round" />
                  </svg>
                </i>
              </button>
              <mat-menu #catemenu="matMenu" class="mat_dropdown bg-black scroll_56">
                <button mat-menu-item *ngFor=" let cat of categoriesData">
                  {{cat.id}}
                  <span (click)="categoryDetail(cat.id)">{{cat.name}}</span>
                </button>
                <!-- <button mat-menu-item>
                  <span>BDSM</span>
                </button>
                <button mat-menu-item>
                  <span>Bondage</span>
                </button>
                <button mat-menu-item>
                  <span>Corporal Punishment</span>
                </button>
                <button mat-menu-item>
                  <span>Domination</span>
                </button>
                <button mat-menu-item>
                  <span>Humiliation</span>
                </button>
                <button mat-menu-item>
                  <span>Lesbian</span>
                </button>
                <button mat-menu-item>
                  <span>Role Play</span>
                </button> -->
                <hr>
                <button mat-menu-item class="color_purple fw_semiBold" [routerLink]="['/all-categories']">View All
                  Categories</button>
              </mat-menu>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" [routerLink]="['/models']" [routerLinkActive]="['active']">Models</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" [routerLink]="['/studios']" [routerLinkActive]="['active']">Studios</a>
          </li>
        </ul>
      </div>
      <div class="rightSideNav">
        <ul class="navbar-nav">
          <!-- <li class="list-inline-item me-4">
            <a class="text-decoration-none text-white">
              <i class="listIcon me-2">
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M8.97731 0C4.03029 0 0.000488281 4.02842 0.000488281 8.97378C0.000488281 13.9191 4.03029 17.9554 8.97731 17.9554C11.0903 17.9554 13.0337 17.2151 14.5696 15.9861L18.3088 19.722C18.4975 19.9029 18.7495 20.0026 19.0109 19.9999C19.2723 19.9973 19.5222 19.8924 19.7072 19.7078C19.8921 19.5231 19.9974 19.2735 20.0004 19.0122C20.0035 18.7509 19.904 18.4988 19.7234 18.3099L15.9842 14.572C17.2147 13.0343 17.9561 11.0885 17.9561 8.97378C17.9561 4.02842 13.9243 0 8.97731 0ZM8.97731 1.99463C12.846 1.99463 15.9589 5.10641 15.9589 8.97378C15.9589 12.8412 12.846 15.9608 8.97731 15.9608C5.10862 15.9608 1.99577 12.8412 1.99577 8.97378C1.99577 5.10641 5.10862 1.99463 8.97731 1.99463Z"
                    fill="white" />
                </svg>
              </i>
            </a>
          </li> -->
          <li class="list-inline-item me-4">
            <a [routerLink]="['/cart']"[routerLinkActive]="['active']" class="text-decoration-none text-white">
              <i class="listIcon me-2">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                  <path
                    d="M19.7057 5.30793C19.5273 4.98555 19.2717 4.7169 18.964 4.52818C18.6563 4.33945 18.3069 4.2371 17.95 4.23111H4.73021L4.13118 1.79748C4.07066 1.56256 3.93564 1.35592 3.7481 1.21123C3.56056 1.06653 3.33155 0.992304 3.09839 1.00063H1.0328C0.758881 1.00063 0.496186 1.11408 0.302499 1.31603C0.108812 1.51797 0 1.79187 0 2.07746C0 2.36305 0.108812 2.63694 0.302499 2.83889C0.496186 3.04083 0.758881 3.15428 1.0328 3.15428H2.31346L5.16398 14.2025C5.2245 14.4374 5.35953 14.6441 5.54707 14.7888C5.73461 14.9335 5.96361 15.0077 6.19678 14.9994H15.4919C15.6827 14.9988 15.8695 14.9431 16.0317 14.8386C16.194 14.734 16.3253 14.5847 16.4111 14.4071L19.7987 7.34314C19.9455 7.02226 20.0139 6.66847 19.9977 6.31335C19.9814 5.95823 19.8812 5.61281 19.7057 5.30793ZM14.8516 12.8457H6.9817L5.29824 6.38476H17.95L14.8516 12.8457Z"
                    fill="white" />
                  <path
                    d="M5.68005 19.5408C6.53565 19.5408 7.22925 18.8472 7.22925 17.9916C7.22925 17.136 6.53565 16.4424 5.68005 16.4424C4.82446 16.4424 4.13086 17.136 4.13086 17.9916C4.13086 18.8472 4.82446 19.5408 5.68005 19.5408Z"
                    fill="white" />
                  <path
                    d="M16.0082 19.5408C16.8638 19.5408 17.5574 18.8472 17.5574 17.9916C17.5574 17.136 16.8638 16.4424 16.0082 16.4424C15.1526 16.4424 14.459 17.136 14.459 17.9916C14.459 18.8472 15.1526 19.5408 16.0082 19.5408Z"
                    fill="white" />
                </svg>
              </i> 
              <!-- <span class="color_red fw_semiBold">(2)</span> -->
            </a>
          </li>
          <li class="list-inline-item d-flex align-items-center">
            <i class="listIcon me-0 me-lg-2">
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                <path
                  d="M10.4168 0C9.36383 0 8.33447 0.308679 7.45893 0.887002C6.58339 1.46533 5.90099 2.28732 5.49803 3.24903C5.09506 4.21075 4.98963 5.269 5.19506 6.28995C5.40049 7.3109 5.90756 8.24871 6.65214 8.98477C7.39673 9.72084 8.34538 10.2221 9.37815 10.4252C10.4109 10.6283 11.4814 10.524 12.4543 10.1257C13.4271 9.72733 14.2586 9.05273 14.8436 8.18721C15.4287 7.32169 15.7409 6.30411 15.7409 5.26316C15.7409 3.86728 15.18 2.52858 14.1815 1.54154C13.1831 0.55451 11.8289 0 10.4168 0ZM10.4168 8.42105C9.78503 8.42105 9.16741 8.23585 8.64209 7.88885C8.11677 7.54186 7.70733 7.04866 7.46555 6.47163C7.22377 5.8946 7.16051 5.25965 7.28376 4.64708C7.40702 4.03451 7.71126 3.47183 8.15802 3.03019C8.60477 2.58855 9.17396 2.28779 9.79362 2.16594C10.4133 2.04409 11.0556 2.10663 11.6393 2.34564C12.223 2.58466 12.7219 2.98941 13.0729 3.50873C13.4239 4.02804 13.6113 4.63859 13.6113 5.26316C13.6113 6.10068 13.2747 6.90391 12.6756 7.49613C12.0766 8.08835 11.264 8.42105 10.4168 8.42105ZM20.0002 20V18.9474C20.0002 16.9931 19.2149 15.119 17.817 13.7371C16.4192 12.3553 14.5233 11.5789 12.5465 11.5789H8.2872C6.31035 11.5789 4.41448 12.3553 3.01664 13.7371C1.61879 15.119 0.833496 16.9931 0.833496 18.9474V20H2.96313V18.9474C2.96313 17.5515 3.52405 16.2128 4.52251 15.2258C5.52097 14.2387 6.87517 13.6842 8.2872 13.6842H12.5465C13.9585 13.6842 15.3127 14.2387 16.3111 15.2258C17.3096 16.2128 17.8705 17.5515 17.8705 18.9474V20H20.0002Z"
                  fill="#F8F8F8" />
              </svg>
            </i>
            <button class="bg-transparent btn-dark border-0 d-flex" mat-icon-button [matMenuTriggerFor]="usermenu"
              aria-label="Example icon-button with a menu">
              <span class="d-none d-lg-flex">{{memberName}}</span>
              <i class="icon ms-2">
                <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
                  <path d="M1 4L5 8L9 4" stroke="#F8F8F8" stroke-width="1.5" stroke-linecap="round"
                    stroke-linejoin="round" />
                </svg>
              </i>
            </button>
            <mat-menu #usermenu="matMenu" class="mat_dropdown bg-black">
              <button mat-menu-item [routerLink]="['/my-profile']">
                <i class="icon me-4"><img src="assets/images/drop_user.svg" /></i>
                <span>My Profile</span>
              </button>
              <button mat-menu-item [routerLink]="['/notification']">
                <i class="icon me-4"><img src="assets/images/drop_notification.svg" /></i>
                <span>Notification</span>
              </button>
              <button mat-menu-item [routerLink]="['/favorites']">
                <i class="icon me-4"><svg xmlns="http://www.w3.org/2000/svg" width="20.927" height="20"
                    viewBox="0 0 26.927 24">
                    <g id="Icon_feather-heart" data-name="Icon feather-heart" transform="translate(-0.327 -2.497)"
                      fill="none" stroke-linecap="round" stroke-linejoin="round">
                      <path
                        d="M23.485,6.265a6.033,6.033,0,0,0-8.535,0L13.788,7.428,12.625,6.265A6.035,6.035,0,1,0,4.091,14.8l1.163,1.163L13.788,24.5l8.535-8.535L23.485,14.8a6.033,6.033,0,0,0,0-8.535Z"
                        stroke="none" />
                      <path
                        d="M 19.218017578125 4.497261047363281 C 17.67348861694336 4.497261047363281 16.12896156311035 5.086641311645508 14.95075130462646 6.265401840209961 L 13.78795146942139 7.428211212158203 L 12.62515163421631 6.265401840209961 C 10.26840209960938 3.908658981323242 6.447360992431641 3.908660888671875 4.090621948242188 6.265401840209961 C 1.733880996704102 8.622150421142578 1.733880996704102 12.443190574646 4.090621948242188 14.79993057250977 L 13.78795146942139 24.49726104736328 L 23.48528099060059 14.79993057250977 C 25.84280204772949 12.44351100921631 25.84280204772949 8.621820449829102 23.48528099060059 6.265401840209961 C 22.30707168579102 5.086641311645508 20.76254463195801 4.497261047363281 19.218017578125 4.497261047363281 M 19.2180118560791 2.497261047363281 C 21.36453247070312 2.497261047363281 23.38237190246582 3.333351135253906 24.89982223510742 4.851520538330078 C 26.41733169555664 6.368310928344727 27.25342178344727 8.386150360107422 27.25342178344727 10.53266143798828 C 27.25342178344727 12.67918109893799 26.41733169555664 14.69702053070068 24.89916229248047 16.21446990966797 L 15.20216178894043 25.91147041320801 C 14.82709121704102 26.28655052185059 14.31838130950928 26.49726104736328 13.78795146942139 26.49726104736328 C 13.2575216293335 26.49726104736328 12.74881172180176 26.28655052185059 12.37374114990234 25.91147041320801 L 2.676410675048828 16.21414184570312 C -0.4563579559326172 13.08137130737305 -0.4563694000244141 7.983970642089844 2.676401138305664 4.851190567016602 C 4.193981170654297 3.333610534667969 6.211702346801758 2.497840881347656 8.357881546020508 2.497840881347656 C 10.38759994506836 2.497840881347656 12.30235290527344 3.245304107666016 13.78789329528809 4.610126495361328 C 15.27305603027344 3.245044708251953 17.18797302246094 2.497261047363281 19.2180118560791 2.497261047363281 Z"
                        stroke="none" fill="#fff" />
                    </g>
                  </svg></i>
                <span>Favorites</span>
              </button>
              <button mat-menu-item [routerLink]="['/purchased-video']">
                <i class="icon me-4"><img src="assets/images/drop_history.svg" /></i>
                <span>Purchase history</span>
              </button>
              <button mat-menu-item [routerLink]="['/purchased-token-history']">
                <i class="icon me-4"><img src="assets/images/drop_history.svg" /></i>
                <span>Purchase token history</span>
              </button>
              <button mat-menu-item type="button" (click)="logOut()">
                <i class="icon me-4"><img src="assets/images/drop_logout.svg" /></i>
                <span>Logout</span>
              </button>
            </mat-menu>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</header>



<!-- <section class="headerTopbar bg-black">
    <div class="container">
        <div class="row py-2">
            <div class="col">
                <ul class="list-unstyled list-inline m-0">
                    <li class="list-inline-item me-5">Have any question?</li>
                    <li class="list-inline-item"><a href="mailto:info@vhhprod.com" class="text-decoration-none text-white"><i class="listIcon me-2">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                            <path d="M16.3 2H3.7C2.98392 2 2.29716 2.31607 1.79081 2.87868C1.28446 3.44129 1 4.20435 1 5V15C1 15.7956 1.28446 16.5587 1.79081 17.1213C2.29716 17.6839 2.98392 18 3.7 18H16.3C17.0161 18 17.7028 17.6839 18.2092 17.1213C18.7155 16.5587 19 15.7956 19 15V5C19 4.20435 18.7155 3.44129 18.2092 2.87868C17.7028 2.31607 17.0161 2 16.3 2ZM15.697 4L10 8.75L4.303 4H15.697ZM16.3 16H3.7C3.46131 16 3.23239 15.8946 3.0636 15.7071C2.89482 15.5196 2.8 15.2652 2.8 15V5.25L9.46 10.8C9.61579 10.9298 9.80527 11 10 11C10.1947 11 10.3842 10.9298 10.54 10.8L17.2 5.25V15C17.2 15.2652 17.1052 15.5196 16.9364 15.7071C16.7676 15.8946 16.5387 16 16.3 16Z" fill="#9A2B4C"/>
                        </svg>
                    </i> info@vhhprod.com</a></li>
                </ul>
            </div>
            <div class="col text-end">
                <ul class="list-unstyled list-inline m-0">
                  <li class="list-inline-item me-4"><a [routerLink]="['/cart']" class="text-decoration-none text-white">
                    <i class="listIcon me-2">
                      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                        <path d="M19.7057 5.30793C19.5273 4.98555 19.2717 4.7169 18.964 4.52818C18.6563 4.33945 18.3069 4.2371 17.95 4.23111H4.73021L4.13118 1.79748C4.07066 1.56256 3.93564 1.35592 3.7481 1.21123C3.56056 1.06653 3.33155 0.992304 3.09839 1.00063H1.0328C0.758881 1.00063 0.496186 1.11408 0.302499 1.31603C0.108812 1.51797 0 1.79187 0 2.07746C0 2.36305 0.108812 2.63694 0.302499 2.83889C0.496186 3.04083 0.758881 3.15428 1.0328 3.15428H2.31346L5.16398 14.2025C5.2245 14.4374 5.35953 14.6441 5.54707 14.7888C5.73461 14.9335 5.96361 15.0077 6.19678 14.9994H15.4919C15.6827 14.9988 15.8695 14.9431 16.0317 14.8386C16.194 14.734 16.3253 14.5847 16.4111 14.4071L19.7987 7.34314C19.9455 7.02226 20.0139 6.66847 19.9977 6.31335C19.9814 5.95823 19.8812 5.61281 19.7057 5.30793ZM14.8516 12.8457H6.9817L5.29824 6.38476H17.95L14.8516 12.8457Z" fill="white"/>
                        <path d="M5.68005 19.5408C6.53565 19.5408 7.22925 18.8472 7.22925 17.9916C7.22925 17.136 6.53565 16.4424 5.68005 16.4424C4.82446 16.4424 4.13086 17.136 4.13086 17.9916C4.13086 18.8472 4.82446 19.5408 5.68005 19.5408Z" fill="white"/>
                        <path d="M16.0082 19.5408C16.8638 19.5408 17.5574 18.8472 17.5574 17.9916C17.5574 17.136 16.8638 16.4424 16.0082 16.4424C15.1526 16.4424 14.459 17.136 14.459 17.9916C14.459 18.8472 15.1526 19.5408 16.0082 19.5408Z" fill="white"/>
                        </svg>
                  </i> <span class="color_red fw_semiBold">(2)</span> </a></li>
                  <li class="list-inline-item">
                    <i class="listIcon me-2">
                      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                        <path d="M10.4168 0C9.36383 0 8.33447 0.308679 7.45893 0.887002C6.58339 1.46533 5.90099 2.28732 5.49803 3.24903C5.09506 4.21075 4.98963 5.269 5.19506 6.28995C5.40049 7.3109 5.90756 8.24871 6.65214 8.98477C7.39673 9.72084 8.34538 10.2221 9.37815 10.4252C10.4109 10.6283 11.4814 10.524 12.4543 10.1257C13.4271 9.72733 14.2586 9.05273 14.8436 8.18721C15.4287 7.32169 15.7409 6.30411 15.7409 5.26316C15.7409 3.86728 15.18 2.52858 14.1815 1.54154C13.1831 0.55451 11.8289 0 10.4168 0ZM10.4168 8.42105C9.78503 8.42105 9.16741 8.23585 8.64209 7.88885C8.11677 7.54186 7.70733 7.04866 7.46555 6.47163C7.22377 5.8946 7.16051 5.25965 7.28376 4.64708C7.40702 4.03451 7.71126 3.47183 8.15802 3.03019C8.60477 2.58855 9.17396 2.28779 9.79362 2.16594C10.4133 2.04409 11.0556 2.10663 11.6393 2.34564C12.223 2.58466 12.7219 2.98941 13.0729 3.50873C13.4239 4.02804 13.6113 4.63859 13.6113 5.26316C13.6113 6.10068 13.2747 6.90391 12.6756 7.49613C12.0766 8.08835 11.264 8.42105 10.4168 8.42105ZM20.0002 20V18.9474C20.0002 16.9931 19.2149 15.119 17.817 13.7371C16.4192 12.3553 14.5233 11.5789 12.5465 11.5789H8.2872C6.31035 11.5789 4.41448 12.3553 3.01664 13.7371C1.61879 15.119 0.833496 16.9931 0.833496 18.9474V20H2.96313V18.9474C2.96313 17.5515 3.52405 16.2128 4.52251 15.2258C5.52097 14.2387 6.87517 13.6842 8.2872 13.6842H12.5465C13.9585 13.6842 15.3127 14.2387 16.3111 15.2258C17.3096 16.2128 17.8705 17.5515 17.8705 18.9474V20H20.0002Z" fill="#F8F8F8"/>
                        </svg>
                  </i>
                    <button class="bg-transparent btn-dark border-0" mat-icon-button [matMenuTriggerFor]="usermenu" aria-label="Example icon-button with a menu">
                     <span>Jack Daniels</span> 
                     <i class="icon ms-2">
                      <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
                        <path d="M1 4L5 8L9 4" stroke="#F8F8F8" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      </svg>
                     </i>
                    </button>
                    <mat-menu #usermenu="matMenu" class="mat_dropdown bg-black">
                      <button mat-menu-item [routerLink]="['/my-profile']">
                        <i class="icon me-4"><img src="assets/images/drop_user.svg"/></i>
                        <span>My Profile</span>
                      </button>
                      <button mat-menu-item [routerLink]="['/purchased-video']">
                        <i class="icon me-4"><img src="assets/images/drop_history.svg"/></i>
                        <span>Purchase history</span>
                      </button>
                      <button mat-menu-item type="button" [routerLink]="['/landingpage']">
                        <i class="icon me-4"><img src="assets/images/drop_logout.svg"/></i>
                        <span>Logout</span>
                      </button>
                    </mat-menu>
                  </li>
                </ul>
            </div>
        </div>
    </div>
    </section> -->
<!-- Top bar section End -->

<!-- <header class="ff_smooch">
        <div class="container position-relative">
        <nav class="navbar navbar-expand-lg navbar-dark position-absolute">
            <div class="container-fluid">
                
                <a class="navbar-brand d-none" >Navbar</a>
              <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
              </button>
              <div class="collapse navbar-collapse justify-content-lg-center" id="navbarNavDropdown">
                <ul class="navbar-nav text-uppercase">
                  <li class="nav-item">
                    <a class="nav-link" [routerLink]="['/home']" [routerLinkActive]="['active']">Home</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link">
                      <button class="bg-transparent btn-dark border-0" mat-icon-button [matMenuTriggerFor]="catemenu" aria-label="Example icon-button with a menu">
                       <span>Categories</span> 
                       <i class="icon ms-2">
                        <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
                          <path d="M1 4L5 8L9 4" stroke="#F8F8F8" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                       </i>
                      </button>
                      <mat-menu #catemenu="matMenu" class="mat_dropdown bg-black">
                        <button mat-menu-item>
                          <span>Anal</span>
                        </button>
                        <button mat-menu-item>
                          <span>BDSM</span>
                        </button>
                        <button mat-menu-item>
                          <span>Bondage</span>
                        </button>
                        <button mat-menu-item>
                          <span>Corporal Punishment</span>
                        </button>
                        <button mat-menu-item>
                          <span>Domination</span>
                        </button>
                        <button mat-menu-item>
                          <span>Humiliation</span>
                        </button>
                        <button mat-menu-item>
                          <span>Lesbian</span>
                        </button>
                        <button mat-menu-item>
                          <span>Role Play</span>
                        </button>
                        <hr>
                        <button mat-menu-item class="color_purple fw_semiBold" [routerLink]="['/all-categories']">View All Categories</button>
                      </mat-menu>
                    </a>
                  </li>

                 
                  <li class="nav-item">
                    <a class="navbar-brand m-0" >
                        <img class="img-fluid" src="assets/images/logo_header.png" alt="">
                    </a>
                  </li>
                  
                  <li class="nav-item">
                    <a class="nav-link" [routerLink]="['/models']" [routerLinkActive]="['active']">Models</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" [routerLink]="['/studios']" [routerLinkActive]="['active']">Studios</a>
                  </li>
                </ul>
              </div>
              <div class="header_bg" style="background: url(assets/images/header_bg.png) no-repeat top center;"></div>
        </div>
          </nav>
        </div>
    </header> -->