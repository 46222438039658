import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/common/api-service/api.service';
import { MessagehandlingService } from 'src/app/common/message/messagehandling.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  forgotForm: FormGroup
  constructor(private formBuilder: FormBuilder, private api: ApiService, private router: Router, private message: MessagehandlingService) {
    this.forgotForm = this.formBuilder.group({
      email: ['', [Validators.required, this.emailValidator.bind(this)]],
    })
  }
  ngOnInit(): void {
  }

  emailValidator(control: FormControl) {
    if (!(control.value.toLowerCase().match('^[a-z0-9]+(\.[_a-z0-9]+)+(\.[a-z0-9-]+)+([.{1}])(\.[a-z]{1,15})$'))) {
      return { invalidEmail: true };
    }
    return
  }

  
  checkEvent(event:any){
    if(event.target.value=='' && event.which==32){
      event.preventDefault();
    }
  }


  forgotPassword() {
    sessionStorage.setItem('email', this.forgotForm.value.email)
    let fd = new FormData
    fd.append('email', this.forgotForm.value.email)
    this.api.forgotPassword(fd).subscribe((res: any) => {
      if (res.status == 200) {
        this.message.sucessMessage(res.message, 2000)
        this.router.navigate(['/studio-verify-otp'])
      }

    })

  }

}
