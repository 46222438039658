<!-- Footer Section Start -->
<footer>
    <div class="container">
      <div class="footer_container text-center py-5">
      <div class="footerLogo">
        <img src="assets/images/footer_logo.png" alt="">
      </div>
  
      <div class="footer_menu my-5">
        <ul class="list-unstyled list-inline ff_smooch m-0">
          <!-- <li class="list-inline-item" [routerLink]="['/home']">Home</li> -->
          <li class="list-inline-item" [routerLink]="['/about-us']">About Us</li>
          <li class="list-inline-item" [routerLink]="['/contact-us']">Contact Us</li>
          <li class="list-inline-item" [routerLink]="['/privacy-policy']">Privacy Policy</li>
          <li class="list-inline-item" [routerLink]="['/terms-condition']">Terms & Conditions</li>
        </ul>
      </div>
  
      <div class="social_icon">
        <ul class="list-unstyled m-0">
          <li class="list-inline-item twitter_icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
              <g clip-path="url(#clip0_75_50)">
              <path d="M25 4.74844C24.0703 5.15625 23.0797 5.42656 22.0469 5.55781C23.1094 4.92344 23.9203 3.92656 24.3016 2.725C23.3109 3.31562 22.2172 3.73281 21.0516 3.96563C20.1109 2.96406 18.7703 2.34375 17.3078 2.34375C14.4703 2.34375 12.1859 4.64688 12.1859 7.47031C12.1859 7.87656 12.2203 8.26719 12.3047 8.63906C8.04375 8.43125 4.27344 6.38906 1.74062 3.27813C1.29844 4.04531 1.03906 4.92344 1.03906 5.86875C1.03906 7.64375 1.95312 9.21719 3.31562 10.1281C2.49219 10.1125 1.68437 9.87344 1 9.49688C1 9.5125 1 9.53281 1 9.55312C1 12.0437 2.77656 14.1125 5.10625 14.5891C4.68906 14.7031 4.23438 14.7578 3.7625 14.7578C3.43438 14.7578 3.10312 14.7391 2.79219 14.6703C3.45625 16.7 5.34063 18.1922 7.58125 18.2406C5.8375 19.6047 3.62344 20.4266 1.22656 20.4266C0.80625 20.4266 0.403125 20.4078 0 20.3563C2.27031 21.8203 4.96094 22.6562 7.8625 22.6562C17.2937 22.6562 22.45 14.8438 22.45 8.07187C22.45 7.84531 22.4422 7.62656 22.4312 7.40938C23.4484 6.6875 24.3031 5.78594 25 4.74844Z" fill="white"/>
              </g>
              <defs>
              <clipPath id="clip0_75_50">
              <rect width="25" height="25" fill="white"/>
              </clipPath>
              </defs>
              </svg>
          </li>
          <li class="list-inline-item instagram_icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
              <path d="M17.1875 0H7.8125C3.49844 0 0 3.49844 0 7.8125V17.1875C0 21.5016 3.49844 25 7.8125 25H17.1875C21.5016 25 25 21.5016 25 17.1875V7.8125C25 3.49844 21.5016 0 17.1875 0ZM22.6562 17.1875C22.6562 20.2031 20.2031 22.6562 17.1875 22.6562H7.8125C4.79688 22.6562 2.34375 20.2031 2.34375 17.1875V7.8125C2.34375 4.79688 4.79688 2.34375 7.8125 2.34375H17.1875C20.2031 2.34375 22.6562 4.79688 22.6562 7.8125V17.1875Z" fill="white"/>
              <path d="M12.5 6.25C9.04844 6.25 6.25 9.04844 6.25 12.5C6.25 15.9516 9.04844 18.75 12.5 18.75C15.9516 18.75 18.75 15.9516 18.75 12.5C18.75 9.04844 15.9516 6.25 12.5 6.25ZM12.5 16.4062C10.3469 16.4062 8.59375 14.6531 8.59375 12.5C8.59375 10.3453 10.3469 8.59375 12.5 8.59375C14.6531 8.59375 16.4062 10.3453 16.4062 12.5C16.4062 14.6531 14.6531 16.4062 12.5 16.4062Z" fill="white"/>
              <path d="M19.2188 6.61411C19.6787 6.61411 20.0516 6.24125 20.0516 5.7813C20.0516 5.32135 19.6787 4.94849 19.2188 4.94849C18.7588 4.94849 18.386 5.32135 18.386 5.7813C18.386 6.24125 18.7588 6.61411 19.2188 6.61411Z" fill="white"/>
              </svg>
          </li>
          <li class="list-inline-item facebook_icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
              <path d="M9.61252 14.0629C9.5222 14.0629 7.53532 14.0629 6.63219 14.0629C6.15052 14.0629 6 13.8822 6 13.4307C6 12.2265 6 10.9922 6 9.78807C6 9.3064 6.18063 9.15588 6.63219 9.15588H9.61252C9.61252 9.06556 9.61252 7.31951 9.61252 6.5067C9.61252 5.30253 9.82325 4.15856 10.4253 3.10491C11.0575 2.02116 11.9607 1.29866 13.1046 0.877195C13.8572 0.606257 14.6098 0.48584 15.4226 0.48584H18.3729C18.7943 0.48584 18.975 0.666466 18.975 1.08793V4.51981C18.975 4.94127 18.7943 5.1219 18.3729 5.1219C17.56 5.1219 16.7472 5.1219 15.9344 5.15201C15.1216 5.15201 14.7001 5.54336 14.7001 6.38628C14.67 7.28941 14.7001 8.16243 14.7001 9.09567H18.1922C18.6739 9.09567 18.8545 9.27629 18.8545 9.75796V13.4006C18.8545 13.8822 18.704 14.0328 18.1922 14.0328C17.1085 14.0328 14.7905 14.0328 14.7001 14.0328V23.8468C14.7001 24.3585 14.5496 24.5392 14.0077 24.5392C12.7434 24.5392 11.5091 24.5392 10.2447 24.5392C9.79314 24.5392 9.61252 24.3585 9.61252 23.907C9.61252 20.746 9.61252 14.1532 9.61252 14.0629Z" fill="white"/>
              </svg>
          </li>
        </ul>
      </div>
    </div>
  </div>
    <!-- copyright section start -->
    <div class="copyright_container">
      <hr class="m-0" />
      <div class="container">
        <div  class="d-flex justify-content-between align-items-center">

          <p class="m-0 py-4 ">Copyright © 2022. All rights reserved by the <a href=""
              class="color_purple text-decoration-none">VHH Presents Chokechamber</a></p>
    
    
              <p class="m-0 py-4 ">Made With By 	&#9829; <a href="https://parastechnologies.com/"
                class="color_red text-decoration-none "> Paras Technologies</a></p>
              </div>
      </div>
    </div>
  </footer>